/* tslint:disable */
/* eslint-disable */
/**
 * Sunrise Health Journey API
 * Health Journey management API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AssociateBaselineAssessmentAnswersDto
 */
export interface AssociateBaselineAssessmentAnswersDto {
    /**
     * 
     * @type {string}
     * @memberof AssociateBaselineAssessmentAnswersDto
     */
    'baselineCode': string;
    /**
     * 
     * @type {object}
     * @memberof AssociateBaselineAssessmentAnswersDto
     */
    'baselineScore': object;
    /**
     * 
     * @type {Array<BaselineAssessmentAnswerDto>}
     * @memberof AssociateBaselineAssessmentAnswersDto
     */
    'baselineAssessmentAnswers': Array<BaselineAssessmentAnswerDto>;
}
/**
 * 
 * @export
 * @interface AssociateBaselineCodeDto
 */
export interface AssociateBaselineCodeDto {
    /**
     * 
     * @type {string}
     * @memberof AssociateBaselineCodeDto
     */
    'baselineCode': string;
}
/**
 * 
 * @export
 * @interface BaselineAssessmentAnswerDto
 */
export interface BaselineAssessmentAnswerDto {
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentAnswerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentAnswerDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentAnswerDto
     */
    'baselineId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaselineAssessmentAnswerDto
     */
    'questionId': number;
    /**
     * 
     * @type {number}
     * @memberof BaselineAssessmentAnswerDto
     */
    'responseId': number;
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentAnswerDto
     */
    'baselineCode'?: string;
}
/**
 * 
 * @export
 * @interface BaselineAssessmentWaitlistDto
 */
export interface BaselineAssessmentWaitlistDto {
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentWaitlistDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentWaitlistDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BaselineAssessmentWaitlistDto
     */
    'emailAddress': string;
}
/**
 * 
 * @export
 * @interface BaselineAssessmentWaitlistPaginatedResult
 */
export interface BaselineAssessmentWaitlistPaginatedResult {
    /**
     * 
     * @type {number}
     * @memberof BaselineAssessmentWaitlistPaginatedResult
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof BaselineAssessmentWaitlistPaginatedResult
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof BaselineAssessmentWaitlistPaginatedResult
     */
    'offset': number;
    /**
     * 
     * @type {Array<BaselineAssessmentWaitlistDto>}
     * @memberof BaselineAssessmentWaitlistPaginatedResult
     */
    'results': Array<BaselineAssessmentWaitlistDto>;
}
/**
 * 
 * @export
 * @interface CoachNote
 */
export interface CoachNote {
    /**
     * 
     * @type {string}
     * @memberof CoachNote
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNote
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNote
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNote
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNote
     */
    'authorId': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNote
     */
    'note': string;
    /**
     * 
     * @type {number}
     * @memberof CoachNote
     */
    'noteNumber': number;
}
/**
 * 
 * @export
 * @interface CoachNoteDto
 */
export interface CoachNoteDto {
    /**
     * 
     * @type {string}
     * @memberof CoachNoteDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNoteDto
     */
    'note': string;
    /**
     * 
     * @type {number}
     * @memberof CoachNoteDto
     */
    'noteNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CoachNoteDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNoteDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CoachNoteDto
     */
    'accountId': string;
    /**
     * 
     * @type {UserDto}
     * @memberof CoachNoteDto
     */
    'author': UserDto;
}
/**
 * 
 * @export
 * @interface CoachNotePaginatedResultDto
 */
export interface CoachNotePaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof CoachNotePaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof CoachNotePaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof CoachNotePaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<CoachNoteDto>}
     * @memberof CoachNotePaginatedResultDto
     */
    'results': Array<CoachNoteDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CommunityMemberType {
    Member = 'member',
    HelloFamTeam = 'hello-fam-team',
    KeyOpinionLeader = 'key-opinion-leader'
}

/**
 * 
 * @export
 * @interface CreateAllBaselineAssessmentAnswersDto
 */
export interface CreateAllBaselineAssessmentAnswersDto {
    /**
     * 
     * @type {Array<CreateBaselineAssessmentAnswerDto>}
     * @memberof CreateAllBaselineAssessmentAnswersDto
     */
    'baselineAssessmentAnswers': Array<CreateBaselineAssessmentAnswerDto>;
}
/**
 * 
 * @export
 * @interface CreateBaselineAssessmentAnswerDto
 */
export interface CreateBaselineAssessmentAnswerDto {
    /**
     * 
     * @type {number}
     * @memberof CreateBaselineAssessmentAnswerDto
     */
    'questionId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBaselineAssessmentAnswerDto
     */
    'responseId': number;
}
/**
 * 
 * @export
 * @interface CreateBaselineAssessmentWaitlistDto
 */
export interface CreateBaselineAssessmentWaitlistDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBaselineAssessmentWaitlistDto
     */
    'emailAddress': string;
}
/**
 * 
 * @export
 * @interface CreateCategorySampleDto
 */
export interface CreateCategorySampleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCategorySampleDto
     */
    'externalId': string;
    /**
     * 
     * @type {HealthDataCategoryType}
     * @memberof CreateCategorySampleDto
     */
    'categoryType': HealthDataCategoryType;
    /**
     * 
     * @type {string}
     * @memberof CreateCategorySampleDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategorySampleDto
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCategorySampleDto
     */
    'value': number;
    /**
     * 
     * @type {object}
     * @memberof CreateCategorySampleDto
     */
    'device'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateCategorySampleDto
     */
    'sourceRevision'?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateCategorySampleDto
     */
    'metadata'?: object;
}
/**
 * 
 * @export
 * @interface CreateCoachNoteDto
 */
export interface CreateCoachNoteDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCoachNoteDto
     */
    'note': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCoachNoteDto
     */
    'accountId': string;
}
/**
 * 
 * @export
 * @interface CreateFeedAppointmentDto
 */
export interface CreateFeedAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFeedAppointmentDto
     */
    'status': CreateFeedAppointmentDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedAppointmentDto
     */
    'queueEntryId'?: string;
    /**
     * 
     * @type {QueueEntryDto}
     * @memberof CreateFeedAppointmentDto
     */
    'queueEntry'?: QueueEntryDto;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedAppointmentDto
     */
    'meetingId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedAppointmentDto
     */
    'password'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateFeedAppointmentDtoStatusEnum {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted'
}

/**
 * 
 * @export
 * @interface CreateFeedDto
 */
export interface CreateFeedDto {
    /**
     * 
     * @type {FeedEntryType}
     * @memberof CreateFeedDto
     */
    'feedEntryType': FeedEntryType;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'content'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateFeedDto
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeedDto
     */
    'pinnedUntil'?: string;
    /**
     * 
     * @type {CreateFeedAppointmentDto}
     * @memberof CreateFeedDto
     */
    'feedAppointment'?: CreateFeedAppointmentDto;
    /**
     * 
     * @type {FeedAttachmentDto}
     * @memberof CreateFeedDto
     */
    'feedAttachment'?: FeedAttachmentDto;
    /**
     * 
     * @type {FeedHealthDataStatisticSummaryDto}
     * @memberof CreateFeedDto
     */
    'healthData'?: FeedHealthDataStatisticSummaryDto;
    /**
     * 
     * @type {FeedStoryConfigurationDto}
     * @memberof CreateFeedDto
     */
    'storyConfiguration'?: FeedStoryConfigurationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateFeedDto
     */
    'hideUsers'?: Array<string>;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof CreateFeedDto
     */
    'healthOccupationAreaType'?: HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface CreateHealthServiceDto
 */
export interface CreateHealthServiceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHealthServiceDto
     */
    'description': string;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof CreateHealthServiceDto
     */
    'occupationAreaType': HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface CreateQuantitySampleDto
 */
export interface CreateQuantitySampleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'externalId': string;
    /**
     * 
     * @type {HealthDataQuantityType}
     * @memberof CreateQuantitySampleDto
     */
    'quantityType': HealthDataQuantityType;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'unit': string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantitySampleDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'device'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'sourceRevision'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantitySampleDto
     */
    'metadata'?: string;
}
/**
 * 
 * @export
 * @interface CreateQuantityStatisticDto
 */
export interface CreateQuantityStatisticDto {
    /**
     * 
     * @type {HealthDataQuantityType}
     * @memberof CreateQuantityStatisticDto
     */
    'quantityType': HealthDataQuantityType;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantityStatisticDto
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantityStatisticDto
     */
    'toDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantityStatisticDto
     */
    'unit': string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantityStatisticDto
     */
    'averageQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantityStatisticDto
     */
    'maximumQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantityStatisticDto
     */
    'minimumQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantityStatisticDto
     */
    'sumQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantityStatisticDto
     */
    'mostRecentQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantityStatisticDto
     */
    'mostRecentQuantityFromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuantityStatisticDto
     */
    'mostRecentQuantityToDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateQuantityStatisticDto
     */
    'duration'?: number;
}
/**
 * 
 * @export
 * @interface CreateServiceInterestDto
 */
export interface CreateServiceInterestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateServiceInterestDto
     */
    'accountIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceInterestDto
     */
    'healthServiceId': string;
}
/**
 * 
 * @export
 * @interface CreateStoryQuestionDto
 */
export interface CreateStoryQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateStoryQuestionDto
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface Feed
 */
export interface Feed {
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'timestamp': string;
    /**
     * 
     * @type {FeedEntryType}
     * @memberof Feed
     */
    'feedEntryType': FeedEntryType;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'content': string;
    /**
     * 
     * @type {object}
     * @memberof Feed
     */
    'dynamicData': object;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    'pinnedUntil'?: string;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof Feed
     */
    'healthOccupationAreaType': HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface FeedAppointmentDto
 */
export interface FeedAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof FeedAppointmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAppointmentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAppointmentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAppointmentDto
     */
    'status'?: FeedAppointmentDtoStatusEnum;
    /**
     * 
     * @type {QueueEntryDto}
     * @memberof FeedAppointmentDto
     */
    'queueEntry'?: QueueEntryDto;
    /**
     * 
     * @type {string}
     * @memberof FeedAppointmentDto
     */
    'meetingId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedAppointmentDto
     */
    'password'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum FeedAppointmentDtoStatusEnum {
    Created = 'created',
    Updated = 'updated',
    Deleted = 'deleted'
}

/**
 * 
 * @export
 * @interface FeedAttachment
 */
export interface FeedAttachment {
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'attachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof FeedAttachment
     */
    'attachmentSize': number;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'bucketName': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachment
     */
    'thumbnailKey'?: string;
    /**
     * 
     * @type {Feed}
     * @memberof FeedAttachment
     */
    'feed': Feed;
}
/**
 * 
 * @export
 * @interface FeedAttachmentDto
 */
export interface FeedAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'attachmentName': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'mimeType': string;
    /**
     * 
     * @type {number}
     * @memberof FeedAttachmentDto
     */
    'attachmentSize': number;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'attachmentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof FeedAttachmentDto
     */
    'thumbnailUrl'?: string;
}
/**
 * 
 * @export
 * @interface FeedDto
 */
export interface FeedDto {
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'timestamp': string;
    /**
     * 
     * @type {FeedEntryType}
     * @memberof FeedDto
     */
    'feedEntryType': FeedEntryType;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'accountId': string;
    /**
     * 
     * @type {object}
     * @memberof FeedDto
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {FeedAppointmentDto}
     * @memberof FeedDto
     */
    'feedAppointment'?: FeedAppointmentDto;
    /**
     * 
     * @type {FeedAttachmentDto}
     * @memberof FeedDto
     */
    'feedAttachment'?: FeedAttachmentDto;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedDto
     */
    'pinnedUntil'?: string;
    /**
     * 
     * @type {FeedHealthDataStatisticSummaryDto}
     * @memberof FeedDto
     */
    'healthData'?: FeedHealthDataStatisticSummaryDto;
    /**
     * 
     * @type {StoryConfigurationDto}
     * @memberof FeedDto
     */
    'storyConfiguration'?: StoryConfigurationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedDto
     */
    'hideUsers'?: Array<string>;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof FeedDto
     */
    'healthOccupationAreaType'?: HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FeedEntryType {
    AccountCreated = 'account_created',
    IntroducingWellnessTeam = 'introducing_wellness_team',
    LinkAppleHealth = 'link_apple_health',
    StartAChat = 'start_a_chat',
    AppointmentScheduled = 'appointment_scheduled',
    AppointmentComingSoon = 'appointment_coming_soon',
    AppointmentNow = 'appointment_now',
    General = 'general',
    IntroducingHealthSummary = 'introducing_health_summary',
    CreateHealthStory = 'create_health_story',
    FullAccessToHelloFamTeam = 'full_access_to_hello_fam_team',
    HealthServiceInterest = 'health_service_interest',
    LongevityJourney = 'longevity_journey',
    ProgramExpectations = 'program_expectations',
    NeedHelpGettingStarted = 'need_help_getting_started',
    HealthDataWeeklyStepCount = 'health_data_weekly_step_count',
    HealthDataWeeklyHeartRate = 'health_data_weekly_heart_rate',
    HealthDataWeeklyActiveEnergyBurned = 'health_data_weekly_active_energy_burned',
    HealthDataMonthlyStepCount = 'health_data_monthly_step_count',
    HealthDataMonthlyHeartRate = 'health_data_monthly_heart_rate',
    HealthDataMonthlyActiveEnergyBurned = 'health_data_monthly_active_energy_burned',
    HealthDataHighHeartRate = 'health_data_high_heart_rate',
    HealthDataLowHeartRate = 'health_data_low_heart_rate',
    HealthDataIrregularHeartRhythm = 'health_data_irregular_heart_rhythm',
    HealthDataBodyMass = 'health_data_body_mass',
    HealthStory = 'health_story',
    ProgramLifespan = 'program_lifespan',
    ProgramNoticiableResults = 'program_noticiable_results',
    ProgramNextStep = 'program_next_step',
    ProgramActive = 'program_active'
}

/**
 * 
 * @export
 * @interface FeedHealthDataQuantityDto
 */
export interface FeedHealthDataQuantityDto {
    /**
     * 
     * @type {HealthDataQuantityType}
     * @memberof FeedHealthDataQuantityDto
     */
    'dataKey'?: HealthDataQuantityType;
    /**
     * 
     * @type {string}
     * @memberof FeedHealthDataQuantityDto
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof FeedHealthDataQuantityDto
     */
    'toDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataQuantityDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataQuantityDto
     */
    'averageQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataQuantityDto
     */
    'maximumQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataQuantityDto
     */
    'minimumQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataQuantityDto
     */
    'sumQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedHealthDataQuantityDto
     */
    'unit'?: string;
}
/**
 * 
 * @export
 * @interface FeedHealthDataStatisticSummaryDto
 */
export interface FeedHealthDataStatisticSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'toDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'periodAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'periodMaximum'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'periodMinimum'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'periodSum'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'unit'?: string;
    /**
     * 
     * @type {Array<FeedHealthDataQuantityDto>}
     * @memberof FeedHealthDataStatisticSummaryDto
     */
    'data'?: Array<FeedHealthDataQuantityDto>;
}
/**
 * 
 * @export
 * @interface FeedPaginatedResultDto
 */
export interface FeedPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof FeedPaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof FeedPaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof FeedPaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<FeedDto>}
     * @memberof FeedPaginatedResultDto
     */
    'results': Array<FeedDto>;
}
/**
 * 
 * @export
 * @interface FeedStoryConfigurationDto
 */
export interface FeedStoryConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof FeedStoryConfigurationDto
     */
    'id': string;
    /**
     * 
     * @type {Array<StoryQuestionConfigurationDto>}
     * @memberof FeedStoryConfigurationDto
     */
    'storyQuestionConfigurations'?: Array<StoryQuestionConfigurationDto>;
}
/**
 * 
 * @export
 * @interface FinishBaselineAssessmentDto
 */
export interface FinishBaselineAssessmentDto {
    /**
     * 
     * @type {string}
     * @memberof FinishBaselineAssessmentDto
     */
    'accountId': string;
}
/**
 * The user\'s biological sex
 * @export
 * @enum {string}
 */

export enum HealthDataBiologicalSex {
    NotSet = 'notSet',
    Female = 'female',
    Male = 'male',
    Other = 'other'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum HealthDataCategoryType {
    HkCategoryTypeIdentifierSleepAnalysis = 'HKCategoryTypeIdentifierSleepAnalysis',
    HkCategoryTypeIdentifierHighHeartRateEvent = 'HKCategoryTypeIdentifierHighHeartRateEvent',
    HkCategoryTypeIdentifierLowHeartRateEvent = 'HKCategoryTypeIdentifierLowHeartRateEvent',
    HkCategoryTypeIdentifierIrregularHeartRhythmEvent = 'HKCategoryTypeIdentifierIrregularHeartRhythmEvent'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum HealthDataQuantityType {
    HkQuantityTypeIdentifierStepCount = 'HKQuantityTypeIdentifierStepCount',
    HkQuantityTypeIdentifierActiveEnergyBurned = 'HKQuantityTypeIdentifierActiveEnergyBurned',
    HkQuantityTypeIdentifierHeartRate = 'HKQuantityTypeIdentifierHeartRate',
    HkQuantityTypeIdentifierHeight = 'HKQuantityTypeIdentifierHeight',
    HkQuantityTypeIdentifierBodyMass = 'HKQuantityTypeIdentifierBodyMass',
    HkQuantityTypeIdentifierBodyMassIndex = 'HKQuantityTypeIdentifierBodyMassIndex'
}

/**
 * 
 * @export
 * @interface HealthDataUser
 */
export interface HealthDataUser {
    /**
     * 
     * @type {string}
     * @memberof HealthDataUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthDataUser
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof HealthDataUser
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof HealthDataUser
     */
    'userId': string;
    /**
     * 
     * @type {HealthDataBiologicalSex}
     * @memberof HealthDataUser
     */
    'biologicalSex'?: HealthDataBiologicalSex;
    /**
     * 
     * @type {string}
     * @memberof HealthDataUser
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HealthDataUser
     */
    'isAppleHealthLinked': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum HealthOccupationAreaType {
    GeneralHealth = 'general_health',
    PhysicalTherapy = 'physical_therapy',
    MentalHealth = 'mental_health',
    SleepAndRecovery = 'sleep_and_recovery',
    Nutrition = 'nutrition'
}

/**
 * 
 * @export
 * @interface HealthService
 */
export interface HealthService {
    /**
     * 
     * @type {string}
     * @memberof HealthService
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthService
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof HealthService
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof HealthService
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof HealthService
     */
    'description': string;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof HealthService
     */
    'occupationAreaType': HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface HealthServiceDto
 */
export interface HealthServiceDto {
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof HealthServiceDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof HealthServiceDto
     */
    'occupationAreaType': HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface HealthServicePaginatedResultDto
 */
export interface HealthServicePaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof HealthServicePaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof HealthServicePaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof HealthServicePaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<HealthServiceDto>}
     * @memberof HealthServicePaginatedResultDto
     */
    'results': Array<HealthServiceDto>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface QuestionDto
 */
export interface QuestionDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'title': string;
    /**
     * 
     * @type {Array<QuestionResponseOptionDto>}
     * @memberof QuestionDto
     */
    'responses'?: Array<QuestionResponseOptionDto>;
    /**
     * 
     * @type {Array<QuestionDto>}
     * @memberof QuestionDto
     */
    'questions'?: Array<QuestionDto>;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'tip': string;
    /**
     * 
     * @type {string}
     * @memberof QuestionDto
     */
    'subtitle': string;
}
/**
 * 
 * @export
 * @interface QuestionResponseOptionDto
 */
export interface QuestionResponseOptionDto {
    /**
     * 
     * @type {number}
     * @memberof QuestionResponseOptionDto
     */
    'option': number;
    /**
     * 
     * @type {string}
     * @memberof QuestionResponseOptionDto
     */
    'text': string;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponseOptionDto
     */
    'hazard_ratio'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionResponseOptionDto
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface QueueEntryDto
 */
export interface QueueEntryDto {
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {object}
     * @memberof QueueEntryDto
     */
    'queue': object;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'coachId'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'status': QueueEntryDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'scheduledForStart': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'scheduledForEnd': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'appointmentType': QueueEntryDtoAppointmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'physicianId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryDtoStatusEnum {
    Pending = 'pending',
    Scheduled = 'scheduled',
    Assigned = 'assigned',
    InProgress = 'in_progress',
    Completed = 'completed'
}
/**
    * @export
    * @enum {string}
    */
export enum QueueEntryDtoAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 * 
 * @export
 * @interface ServiceInterestDto
 */
export interface ServiceInterestDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceInterestDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInterestDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInterestDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInterestDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInterestDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<HealthServiceDto>}
     * @memberof ServiceInterestDto
     */
    'healthServices': Array<HealthServiceDto>;
}
/**
 * 
 * @export
 * @interface ServiceInterestPaginatedResultDto
 */
export interface ServiceInterestPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceInterestPaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceInterestPaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceInterestPaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<ServiceInterestDto>}
     * @memberof ServiceInterestPaginatedResultDto
     */
    'results': Array<ServiceInterestDto>;
}
/**
 * 
 * @export
 * @interface StoryConfigurationDto
 */
export interface StoryConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof StoryConfigurationDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof StoryConfigurationDto
     */
    'duration': number;
    /**
     * 
     * @type {string}
     * @memberof StoryConfigurationDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StoryConfigurationDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof StoryConfigurationDto
     */
    'configurationSequency': number | null;
    /**
     * 
     * @type {Array<StoryQuestionDto>}
     * @memberof StoryConfigurationDto
     */
    'storyQuestions': Array<StoryQuestionDto>;
}
/**
 * 
 * @export
 * @interface StoryQuestion
 */
export interface StoryQuestion {
    /**
     * 
     * @type {string}
     * @memberof StoryQuestion
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoryQuestion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof StoryQuestion
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StoryQuestion
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface StoryQuestionConfigurationDto
 */
export interface StoryQuestionConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof StoryQuestionConfigurationDto
     */
    'storyQuestionId': string;
    /**
     * 
     * @type {number}
     * @memberof StoryQuestionConfigurationDto
     */
    'sequency': number;
}
/**
 * 
 * @export
 * @interface StoryQuestionDto
 */
export interface StoryQuestionDto {
    /**
     * 
     * @type {string}
     * @memberof StoryQuestionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StoryQuestionDto
     */
    'question': string;
    /**
     * 
     * @type {number}
     * @memberof StoryQuestionDto
     */
    'sequency': number;
    /**
     * 
     * @type {string}
     * @memberof StoryQuestionDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof StoryQuestionDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpdateCoachNoteDto
 */
export interface UpdateCoachNoteDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCoachNoteDto
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCoachNoteDto
     */
    'accountId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFeedDto
 */
export interface UpdateFeedDto {
    /**
     * 
     * @type {FeedEntryType}
     * @memberof UpdateFeedDto
     */
    'feedEntryType'?: FeedEntryType;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'content'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateFeedDto
     */
    'dynamicData'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'accountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'expiredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedDto
     */
    'pinnedUntil'?: string;
    /**
     * 
     * @type {CreateFeedAppointmentDto}
     * @memberof UpdateFeedDto
     */
    'feedAppointment'?: CreateFeedAppointmentDto;
    /**
     * 
     * @type {FeedAttachmentDto}
     * @memberof UpdateFeedDto
     */
    'feedAttachment'?: FeedAttachmentDto;
    /**
     * 
     * @type {FeedHealthDataStatisticSummaryDto}
     * @memberof UpdateFeedDto
     */
    'healthData'?: FeedHealthDataStatisticSummaryDto;
    /**
     * 
     * @type {FeedStoryConfigurationDto}
     * @memberof UpdateFeedDto
     */
    'storyConfiguration'?: FeedStoryConfigurationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFeedDto
     */
    'hideUsers'?: Array<string>;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof UpdateFeedDto
     */
    'healthOccupationAreaType'?: HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface UpdateHealthDataUserDto
 */
export interface UpdateHealthDataUserDto {
    /**
     * 
     * @type {HealthDataBiologicalSex}
     * @memberof UpdateHealthDataUserDto
     */
    'biologicalSex'?: HealthDataBiologicalSex;
    /**
     * 
     * @type {string}
     * @memberof UpdateHealthDataUserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateHealthDataUserDto
     */
    'isAppleHealthLinked'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateHealthServiceDto
 */
export interface UpdateHealthServiceDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHealthServiceDto
     */
    'description'?: string;
    /**
     * 
     * @type {HealthOccupationAreaType}
     * @memberof UpdateHealthServiceDto
     */
    'occupationAreaType'?: HealthOccupationAreaType;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'userType': UserDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityRole': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityBio': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityWebsiteLink': string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof UserDto
     */
    'communityMemberType': CommunityMemberType;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach',
    Assistant = 'assistant'
}


/**
 * BaselineAssessmentApi - axios parameter creator
 * @export
 */
export const BaselineAssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FinishBaselineAssessmentDto} finishBaselineAssessmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentControllerFinishBaselineAssessment: async (finishBaselineAssessmentDto: FinishBaselineAssessmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'finishBaselineAssessmentDto' is not null or undefined
            assertParamExists('baselineAssessmentControllerFinishBaselineAssessment', 'finishBaselineAssessmentDto', finishBaselineAssessmentDto)
            const localVarPath = `/v1/baseline-assessment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finishBaselineAssessmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentControllerGetBaselineQuestionById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('baselineAssessmentControllerGetBaselineQuestionById', 'id', id)
            const localVarPath = `/v1/baseline-assessment/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentControllerGetBaselineQuestions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/baseline-assessment/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaselineAssessmentApi - functional programming interface
 * @export
 */
export const BaselineAssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaselineAssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FinishBaselineAssessmentDto} finishBaselineAssessmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentControllerFinishBaselineAssessment(finishBaselineAssessmentDto: FinishBaselineAssessmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentControllerFinishBaselineAssessment(finishBaselineAssessmentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentControllerGetBaselineQuestionById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentControllerGetBaselineQuestionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentControllerGetBaselineQuestions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentControllerGetBaselineQuestions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaselineAssessmentApi - factory interface
 * @export
 */
export const BaselineAssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaselineAssessmentApiFp(configuration)
    return {
        /**
         * 
         * @param {FinishBaselineAssessmentDto} finishBaselineAssessmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentControllerFinishBaselineAssessment(finishBaselineAssessmentDto: FinishBaselineAssessmentDto, options?: any): AxiosPromise<Array<FeedDto>> {
            return localVarFp.baselineAssessmentControllerFinishBaselineAssessment(finishBaselineAssessmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentControllerGetBaselineQuestionById(id: number, options?: any): AxiosPromise<QuestionDto> {
            return localVarFp.baselineAssessmentControllerGetBaselineQuestionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentControllerGetBaselineQuestions(options?: any): AxiosPromise<Array<QuestionDto>> {
            return localVarFp.baselineAssessmentControllerGetBaselineQuestions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaselineAssessmentApi - object-oriented interface
 * @export
 * @class BaselineAssessmentApi
 * @extends {BaseAPI}
 */
export class BaselineAssessmentApi extends BaseAPI {
    /**
     * 
     * @param {FinishBaselineAssessmentDto} finishBaselineAssessmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentApi
     */
    public baselineAssessmentControllerFinishBaselineAssessment(finishBaselineAssessmentDto: FinishBaselineAssessmentDto, options?: AxiosRequestConfig) {
        return BaselineAssessmentApiFp(this.configuration).baselineAssessmentControllerFinishBaselineAssessment(finishBaselineAssessmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentApi
     */
    public baselineAssessmentControllerGetBaselineQuestionById(id: number, options?: AxiosRequestConfig) {
        return BaselineAssessmentApiFp(this.configuration).baselineAssessmentControllerGetBaselineQuestionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentApi
     */
    public baselineAssessmentControllerGetBaselineQuestions(options?: AxiosRequestConfig) {
        return BaselineAssessmentApiFp(this.configuration).baselineAssessmentControllerGetBaselineQuestions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BaselineAssessmentAnswerApi - axios parameter creator
 * @export
 */
export const BaselineAssessmentAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssociateBaselineCodeDto} associateBaselineCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentAnswerControllerAssociateToBaselineAssessment: async (associateBaselineCodeDto: AssociateBaselineCodeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'associateBaselineCodeDto' is not null or undefined
            assertParamExists('baselineAssessmentAnswerControllerAssociateToBaselineAssessment', 'associateBaselineCodeDto', associateBaselineCodeDto)
            const localVarPath = `/v1/baseline-assessment-answer/associate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(associateBaselineCodeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBaselineAssessmentAnswerDto} createBaselineAssessmentAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentAnswerControllerCreate: async (createBaselineAssessmentAnswerDto: CreateBaselineAssessmentAnswerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBaselineAssessmentAnswerDto' is not null or undefined
            assertParamExists('baselineAssessmentAnswerControllerCreate', 'createBaselineAssessmentAnswerDto', createBaselineAssessmentAnswerDto)
            const localVarPath = `/v1/baseline-assessment-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBaselineAssessmentAnswerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentAnswerControllerCreateAll: async (createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllBaselineAssessmentAnswersDto' is not null or undefined
            assertParamExists('baselineAssessmentAnswerControllerCreateAll', 'createAllBaselineAssessmentAnswersDto', createAllBaselineAssessmentAnswersDto)
            const localVarPath = `/v1/baseline-assessment-answer/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllBaselineAssessmentAnswersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaselineAssessmentAnswerApi - functional programming interface
 * @export
 */
export const BaselineAssessmentAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaselineAssessmentAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AssociateBaselineCodeDto} associateBaselineCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentAnswerControllerAssociateToBaselineAssessment(associateBaselineCodeDto: AssociateBaselineCodeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociateBaselineAssessmentAnswersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentAnswerControllerAssociateToBaselineAssessment(associateBaselineCodeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBaselineAssessmentAnswerDto} createBaselineAssessmentAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentAnswerControllerCreate(createBaselineAssessmentAnswerDto: CreateBaselineAssessmentAnswerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaselineAssessmentAnswerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentAnswerControllerCreate(createBaselineAssessmentAnswerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociateBaselineAssessmentAnswersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaselineAssessmentAnswerApi - factory interface
 * @export
 */
export const BaselineAssessmentAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaselineAssessmentAnswerApiFp(configuration)
    return {
        /**
         * 
         * @param {AssociateBaselineCodeDto} associateBaselineCodeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentAnswerControllerAssociateToBaselineAssessment(associateBaselineCodeDto: AssociateBaselineCodeDto, options?: any): AxiosPromise<AssociateBaselineAssessmentAnswersDto> {
            return localVarFp.baselineAssessmentAnswerControllerAssociateToBaselineAssessment(associateBaselineCodeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBaselineAssessmentAnswerDto} createBaselineAssessmentAnswerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentAnswerControllerCreate(createBaselineAssessmentAnswerDto: CreateBaselineAssessmentAnswerDto, options?: any): AxiosPromise<BaselineAssessmentAnswerDto> {
            return localVarFp.baselineAssessmentAnswerControllerCreate(createBaselineAssessmentAnswerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options?: any): AxiosPromise<AssociateBaselineAssessmentAnswersDto> {
            return localVarFp.baselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaselineAssessmentAnswerApi - object-oriented interface
 * @export
 * @class BaselineAssessmentAnswerApi
 * @extends {BaseAPI}
 */
export class BaselineAssessmentAnswerApi extends BaseAPI {
    /**
     * 
     * @param {AssociateBaselineCodeDto} associateBaselineCodeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentAnswerApi
     */
    public baselineAssessmentAnswerControllerAssociateToBaselineAssessment(associateBaselineCodeDto: AssociateBaselineCodeDto, options?: AxiosRequestConfig) {
        return BaselineAssessmentAnswerApiFp(this.configuration).baselineAssessmentAnswerControllerAssociateToBaselineAssessment(associateBaselineCodeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBaselineAssessmentAnswerDto} createBaselineAssessmentAnswerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentAnswerApi
     */
    public baselineAssessmentAnswerControllerCreate(createBaselineAssessmentAnswerDto: CreateBaselineAssessmentAnswerDto, options?: AxiosRequestConfig) {
        return BaselineAssessmentAnswerApiFp(this.configuration).baselineAssessmentAnswerControllerCreate(createBaselineAssessmentAnswerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentAnswerApi
     */
    public baselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options?: AxiosRequestConfig) {
        return BaselineAssessmentAnswerApiFp(this.configuration).baselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BaselineAssessmentWaitlistApi - axios parameter creator
 * @export
 */
export const BaselineAssessmentWaitlistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [emailAddress] 
         * @param {string} [createdAfter] 
         * @param {string} [createdBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentWaitlistControllerFindAll: async (limit: number, offset: number, order?: string, emailAddress?: string, createdAfter?: string, createdBefore?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('baselineAssessmentWaitlistControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('baselineAssessmentWaitlistControllerFindAll', 'offset', offset)
            const localVarPath = `/v1/baseline-assessment-waitlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = (createdAfter as any instanceof Date) ?
                    (createdAfter as any).toISOString() :
                    createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = (createdBefore as any instanceof Date) ?
                    (createdBefore as any).toISOString() :
                    createdBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaselineAssessmentWaitlistApi - functional programming interface
 * @export
 */
export const BaselineAssessmentWaitlistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaselineAssessmentWaitlistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [emailAddress] 
         * @param {string} [createdAfter] 
         * @param {string} [createdBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async baselineAssessmentWaitlistControllerFindAll(limit: number, offset: number, order?: string, emailAddress?: string, createdAfter?: string, createdBefore?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaselineAssessmentWaitlistPaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.baselineAssessmentWaitlistControllerFindAll(limit, offset, order, emailAddress, createdAfter, createdBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaselineAssessmentWaitlistApi - factory interface
 * @export
 */
export const BaselineAssessmentWaitlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaselineAssessmentWaitlistApiFp(configuration)
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [emailAddress] 
         * @param {string} [createdAfter] 
         * @param {string} [createdBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        baselineAssessmentWaitlistControllerFindAll(limit: number, offset: number, order?: string, emailAddress?: string, createdAfter?: string, createdBefore?: string, options?: any): AxiosPromise<BaselineAssessmentWaitlistPaginatedResult> {
            return localVarFp.baselineAssessmentWaitlistControllerFindAll(limit, offset, order, emailAddress, createdAfter, createdBefore, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaselineAssessmentWaitlistApi - object-oriented interface
 * @export
 * @class BaselineAssessmentWaitlistApi
 * @extends {BaseAPI}
 */
export class BaselineAssessmentWaitlistApi extends BaseAPI {
    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [emailAddress] 
     * @param {string} [createdAfter] 
     * @param {string} [createdBefore] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaselineAssessmentWaitlistApi
     */
    public baselineAssessmentWaitlistControllerFindAll(limit: number, offset: number, order?: string, emailAddress?: string, createdAfter?: string, createdBefore?: string, options?: AxiosRequestConfig) {
        return BaselineAssessmentWaitlistApiFp(this.configuration).baselineAssessmentWaitlistControllerFindAll(limit, offset, order, emailAddress, createdAfter, createdBefore, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoachNoteApi - axios parameter creator
 * @export
 */
export const CoachNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCoachNoteDto} createCoachNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerCreate: async (createCoachNoteDto: CreateCoachNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCoachNoteDto' is not null or undefined
            assertParamExists('coachNoteControllerCreate', 'createCoachNoteDto', createCoachNoteDto)
            const localVarPath = `/v1/coach-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCoachNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coachNoteControllerDelete', 'id', id)
            const localVarPath = `/v1/coach-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [authorId] 
         * @param {Array<'admin' | 'customer' | 'physician' | 'coach'>} [authorUserType] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerFindMany: async (limit: number, offset: number, accountId: string, order?: string, authorId?: string, authorUserType?: Array<'admin' | 'customer' | 'physician' | 'coach'>, updatedAfter?: string, updatedBefore?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('coachNoteControllerFindMany', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('coachNoteControllerFindMany', 'offset', offset)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('coachNoteControllerFindMany', 'accountId', accountId)
            const localVarPath = `/v1/coach-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (authorUserType) {
                localVarQueryParameter['authorUserType'] = authorUserType;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updatedBefore'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coachNoteControllerFindOne', 'id', id)
            const localVarPath = `/v1/coach-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCoachNoteDto} updateCoachNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerUpdate: async (id: string, updateCoachNoteDto: UpdateCoachNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coachNoteControllerUpdate', 'id', id)
            // verify required parameter 'updateCoachNoteDto' is not null or undefined
            assertParamExists('coachNoteControllerUpdate', 'updateCoachNoteDto', updateCoachNoteDto)
            const localVarPath = `/v1/coach-note/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCoachNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoachNoteApi - functional programming interface
 * @export
 */
export const CoachNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoachNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCoachNoteDto} createCoachNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coachNoteControllerCreate(createCoachNoteDto: CreateCoachNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coachNoteControllerCreate(createCoachNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coachNoteControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coachNoteControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [authorId] 
         * @param {Array<'admin' | 'customer' | 'physician' | 'coach'>} [authorUserType] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coachNoteControllerFindMany(limit: number, offset: number, accountId: string, order?: string, authorId?: string, authorUserType?: Array<'admin' | 'customer' | 'physician' | 'coach'>, updatedAfter?: string, updatedBefore?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachNotePaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coachNoteControllerFindMany(limit, offset, accountId, order, authorId, authorUserType, updatedAfter, updatedBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coachNoteControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coachNoteControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCoachNoteDto} updateCoachNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coachNoteControllerUpdate(id: string, updateCoachNoteDto: UpdateCoachNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoachNote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coachNoteControllerUpdate(id, updateCoachNoteDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoachNoteApi - factory interface
 * @export
 */
export const CoachNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoachNoteApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCoachNoteDto} createCoachNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerCreate(createCoachNoteDto: CreateCoachNoteDto, options?: any): AxiosPromise<CoachNote> {
            return localVarFp.coachNoteControllerCreate(createCoachNoteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.coachNoteControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [authorId] 
         * @param {Array<'admin' | 'customer' | 'physician' | 'coach'>} [authorUserType] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerFindMany(limit: number, offset: number, accountId: string, order?: string, authorId?: string, authorUserType?: Array<'admin' | 'customer' | 'physician' | 'coach'>, updatedAfter?: string, updatedBefore?: string, options?: any): AxiosPromise<CoachNotePaginatedResultDto> {
            return localVarFp.coachNoteControllerFindMany(limit, offset, accountId, order, authorId, authorUserType, updatedAfter, updatedBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerFindOne(id: string, options?: any): AxiosPromise<CoachNoteDto> {
            return localVarFp.coachNoteControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCoachNoteDto} updateCoachNoteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coachNoteControllerUpdate(id: string, updateCoachNoteDto: UpdateCoachNoteDto, options?: any): AxiosPromise<CoachNote> {
            return localVarFp.coachNoteControllerUpdate(id, updateCoachNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CoachNoteApi - object-oriented interface
 * @export
 * @class CoachNoteApi
 * @extends {BaseAPI}
 */
export class CoachNoteApi extends BaseAPI {
    /**
     * 
     * @param {CreateCoachNoteDto} createCoachNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachNoteApi
     */
    public coachNoteControllerCreate(createCoachNoteDto: CreateCoachNoteDto, options?: AxiosRequestConfig) {
        return CoachNoteApiFp(this.configuration).coachNoteControllerCreate(createCoachNoteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachNoteApi
     */
    public coachNoteControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CoachNoteApiFp(this.configuration).coachNoteControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} accountId 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [authorId] 
     * @param {Array<'admin' | 'customer' | 'physician' | 'coach'>} [authorUserType] 
     * @param {string} [updatedAfter] 
     * @param {string} [updatedBefore] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachNoteApi
     */
    public coachNoteControllerFindMany(limit: number, offset: number, accountId: string, order?: string, authorId?: string, authorUserType?: Array<'admin' | 'customer' | 'physician' | 'coach'>, updatedAfter?: string, updatedBefore?: string, options?: AxiosRequestConfig) {
        return CoachNoteApiFp(this.configuration).coachNoteControllerFindMany(limit, offset, accountId, order, authorId, authorUserType, updatedAfter, updatedBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachNoteApi
     */
    public coachNoteControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return CoachNoteApiFp(this.configuration).coachNoteControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateCoachNoteDto} updateCoachNoteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoachNoteApi
     */
    public coachNoteControllerUpdate(id: string, updateCoachNoteDto: UpdateCoachNoteDto, options?: AxiosRequestConfig) {
        return CoachNoteApiFp(this.configuration).coachNoteControllerUpdate(id, updateCoachNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedApi - axios parameter creator
 * @export
 */
export const FeedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFeedDto} createFeedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerCreate: async (createFeedDto: CreateFeedDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFeedDto' is not null or undefined
            assertParamExists('feedControllerCreate', 'createFeedDto', createFeedDto)
            const localVarPath = `/v1/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFeedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedControllerDelete', 'id', id)
            const localVarPath = `/v1/feed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>} [feedEntryType] 
         * @param {string} [title] 
         * @param {string} [content] 
         * @param {string} [queueEntryId] 
         * @param {boolean} [attachment] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerFindAll: async (limit: number, offset: number, accountId: string, order?: string, feedEntryType?: Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>, title?: string, content?: string, queueEntryId?: string, attachment?: boolean, updatedAfter?: string, updatedBefore?: string, after?: string, before?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('feedControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('feedControllerFindAll', 'offset', offset)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('feedControllerFindAll', 'accountId', accountId)
            const localVarPath = `/v1/feed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (feedEntryType) {
                localVarQueryParameter['feedEntryType'] = feedEntryType;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (queueEntryId !== undefined) {
                localVarQueryParameter['queueEntryId'] = queueEntryId;
            }

            if (attachment !== undefined) {
                localVarQueryParameter['attachment'] = attachment;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updatedBefore'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = (after as any instanceof Date) ?
                    (after as any).toISOString() :
                    after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = (before as any instanceof Date) ?
                    (before as any).toISOString() :
                    before;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedControllerFindOne', 'id', id)
            const localVarPath = `/v1/feed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFeedDto} updateFeedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerUpdate: async (id: string, updateFeedDto: UpdateFeedDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedControllerUpdate', 'id', id)
            // verify required parameter 'updateFeedDto' is not null or undefined
            assertParamExists('feedControllerUpdate', 'updateFeedDto', updateFeedDto)
            const localVarPath = `/v1/feed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedApi - functional programming interface
 * @export
 */
export const FeedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFeedDto} createFeedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedControllerCreate(createFeedDto: CreateFeedDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedControllerCreate(createFeedDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>} [feedEntryType] 
         * @param {string} [title] 
         * @param {string} [content] 
         * @param {string} [queueEntryId] 
         * @param {boolean} [attachment] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedControllerFindAll(limit: number, offset: number, accountId: string, order?: string, feedEntryType?: Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>, title?: string, content?: string, queueEntryId?: string, attachment?: boolean, updatedAfter?: string, updatedBefore?: string, after?: string, before?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedControllerFindAll(limit, offset, accountId, order, feedEntryType, title, content, queueEntryId, attachment, updatedAfter, updatedBefore, after, before, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFeedDto} updateFeedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedControllerUpdate(id: string, updateFeedDto: UpdateFeedDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Feed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedControllerUpdate(id, updateFeedDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedApi - factory interface
 * @export
 */
export const FeedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFeedDto} createFeedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerCreate(createFeedDto: CreateFeedDto, options?: any): AxiosPromise<FeedDto> {
            return localVarFp.feedControllerCreate(createFeedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.feedControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} accountId 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>} [feedEntryType] 
         * @param {string} [title] 
         * @param {string} [content] 
         * @param {string} [queueEntryId] 
         * @param {boolean} [attachment] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerFindAll(limit: number, offset: number, accountId: string, order?: string, feedEntryType?: Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>, title?: string, content?: string, queueEntryId?: string, attachment?: boolean, updatedAfter?: string, updatedBefore?: string, after?: string, before?: string, options?: any): AxiosPromise<FeedPaginatedResultDto> {
            return localVarFp.feedControllerFindAll(limit, offset, accountId, order, feedEntryType, title, content, queueEntryId, attachment, updatedAfter, updatedBefore, after, before, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerFindOne(id: string, options?: any): AxiosPromise<FeedDto> {
            return localVarFp.feedControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateFeedDto} updateFeedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedControllerUpdate(id: string, updateFeedDto: UpdateFeedDto, options?: any): AxiosPromise<Feed> {
            return localVarFp.feedControllerUpdate(id, updateFeedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedApi - object-oriented interface
 * @export
 * @class FeedApi
 * @extends {BaseAPI}
 */
export class FeedApi extends BaseAPI {
    /**
     * 
     * @param {CreateFeedDto} createFeedDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedControllerCreate(createFeedDto: CreateFeedDto, options?: AxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedControllerCreate(createFeedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedControllerDelete(id: string, options?: AxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} accountId 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>} [feedEntryType] 
     * @param {string} [title] 
     * @param {string} [content] 
     * @param {string} [queueEntryId] 
     * @param {boolean} [attachment] 
     * @param {string} [updatedAfter] 
     * @param {string} [updatedBefore] 
     * @param {string} [after] 
     * @param {string} [before] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedControllerFindAll(limit: number, offset: number, accountId: string, order?: string, feedEntryType?: Array<'account_created' | 'introducing_wellness_team' | 'link_apple_health' | 'start_a_chat' | 'appointment_scheduled' | 'appointment_coming_soon' | 'appointment_now' | 'general' | 'introducing_health_summary' | 'create_health_story' | 'full_access_to_hello_fam_team' | 'health_service_interest' | 'longevity_journey' | 'program_expectations' | 'need_help_getting_started' | 'health_data_weekly_step_count' | 'health_data_weekly_heart_rate' | 'health_data_weekly_active_energy_burned' | 'health_data_monthly_step_count' | 'health_data_monthly_heart_rate' | 'health_data_monthly_active_energy_burned' | 'health_data_high_heart_rate' | 'health_data_low_heart_rate' | 'health_data_irregular_heart_rhythm' | 'health_data_body_mass' | 'health_story' | 'program_lifespan' | 'program_noticiable_results' | 'program_next_step' | 'program_active'>, title?: string, content?: string, queueEntryId?: string, attachment?: boolean, updatedAfter?: string, updatedBefore?: string, after?: string, before?: string, options?: AxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedControllerFindAll(limit, offset, accountId, order, feedEntryType, title, content, queueEntryId, attachment, updatedAfter, updatedBefore, after, before, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateFeedDto} updateFeedDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedControllerUpdate(id: string, updateFeedDto: UpdateFeedDto, options?: AxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedControllerUpdate(id, updateFeedDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedAttachmentApi - axios parameter creator
 * @export
 */
export const FeedAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedAttachmentControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('feedAttachmentControllerFindOne', 'id', id)
            const localVarPath = `/v1/feed-attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedAttachmentControllerUploadAttachment: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/feed-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedAttachmentApi - functional programming interface
 * @export
 */
export const FeedAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedAttachmentControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedAttachmentControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedAttachmentControllerUploadAttachment(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedAttachmentControllerUploadAttachment(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedAttachmentApi - factory interface
 * @export
 */
export const FeedAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedAttachmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedAttachmentControllerFindOne(id: string, options?: any): AxiosPromise<FeedAttachmentDto> {
            return localVarFp.feedAttachmentControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedAttachmentControllerUploadAttachment(file?: any, options?: any): AxiosPromise<FeedAttachmentDto> {
            return localVarFp.feedAttachmentControllerUploadAttachment(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedAttachmentApi - object-oriented interface
 * @export
 * @class FeedAttachmentApi
 * @extends {BaseAPI}
 */
export class FeedAttachmentApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAttachmentApi
     */
    public feedAttachmentControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return FeedAttachmentApiFp(this.configuration).feedAttachmentControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedAttachmentApi
     */
    public feedAttachmentControllerUploadAttachment(file?: any, options?: AxiosRequestConfig) {
        return FeedAttachmentApiFp(this.configuration).feedAttachmentControllerUploadAttachment(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthDataApi - axios parameter creator
 * @export
 */
export const HealthDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCategorySampleDto} createCategorySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateCategorySample: async (createCategorySampleDto: CreateCategorySampleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategorySampleDto' is not null or undefined
            assertParamExists('healthDataControllerCreateCategorySample', 'createCategorySampleDto', createCategorySampleDto)
            const localVarPath = `/v1/health-data/category-sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategorySampleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateCategorySampleDto>} createCategorySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateCategorySampleV2: async (createCategorySampleDto: Array<CreateCategorySampleDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategorySampleDto' is not null or undefined
            assertParamExists('healthDataControllerCreateCategorySampleV2', 'createCategorySampleDto', createCategorySampleDto)
            const localVarPath = `/v2/health-data/category-sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategorySampleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateQuantitySampleDto>} createQuantitySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateQuantitySample: async (createQuantitySampleDto: Array<CreateQuantitySampleDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createQuantitySampleDto' is not null or undefined
            assertParamExists('healthDataControllerCreateQuantitySample', 'createQuantitySampleDto', createQuantitySampleDto)
            const localVarPath = `/v1/health-data/quantity-sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQuantitySampleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateQuantityStatisticDto>} createQuantityStatisticDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateQuantityStatistic: async (createQuantityStatisticDto: Array<CreateQuantityStatisticDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createQuantityStatisticDto' is not null or undefined
            assertParamExists('healthDataControllerCreateQuantityStatistic', 'createQuantityStatisticDto', createQuantityStatisticDto)
            const localVarPath = `/v1/health-data/quantity-statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createQuantityStatisticDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateHealthDataUserDto} updateHealthDataUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerUpdateHealthDataUser: async (updateHealthDataUserDto: UpdateHealthDataUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateHealthDataUserDto' is not null or undefined
            assertParamExists('healthDataControllerUpdateHealthDataUser', 'updateHealthDataUserDto', updateHealthDataUserDto)
            const localVarPath = `/v1/health-data/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHealthDataUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthDataApi - functional programming interface
 * @export
 */
export const HealthDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCategorySampleDto} createCategorySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthDataControllerCreateCategorySample(createCategorySampleDto: CreateCategorySampleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthDataControllerCreateCategorySample(createCategorySampleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateCategorySampleDto>} createCategorySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthDataControllerCreateCategorySampleV2(createCategorySampleDto: Array<CreateCategorySampleDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthDataControllerCreateCategorySampleV2(createCategorySampleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateQuantitySampleDto>} createQuantitySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthDataControllerCreateQuantitySample(createQuantitySampleDto: Array<CreateQuantitySampleDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthDataControllerCreateQuantitySample(createQuantitySampleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateQuantityStatisticDto>} createQuantityStatisticDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthDataControllerCreateQuantityStatistic(createQuantityStatisticDto: Array<CreateQuantityStatisticDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthDataControllerCreateQuantityStatistic(createQuantityStatisticDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateHealthDataUserDto} updateHealthDataUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthDataControllerUpdateHealthDataUser(updateHealthDataUserDto: UpdateHealthDataUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthDataUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthDataControllerUpdateHealthDataUser(updateHealthDataUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthDataApi - factory interface
 * @export
 */
export const HealthDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthDataApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCategorySampleDto} createCategorySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateCategorySample(createCategorySampleDto: CreateCategorySampleDto, options?: any): AxiosPromise<void> {
            return localVarFp.healthDataControllerCreateCategorySample(createCategorySampleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateCategorySampleDto>} createCategorySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateCategorySampleV2(createCategorySampleDto: Array<CreateCategorySampleDto>, options?: any): AxiosPromise<void> {
            return localVarFp.healthDataControllerCreateCategorySampleV2(createCategorySampleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateQuantitySampleDto>} createQuantitySampleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateQuantitySample(createQuantitySampleDto: Array<CreateQuantitySampleDto>, options?: any): AxiosPromise<void> {
            return localVarFp.healthDataControllerCreateQuantitySample(createQuantitySampleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateQuantityStatisticDto>} createQuantityStatisticDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerCreateQuantityStatistic(createQuantityStatisticDto: Array<CreateQuantityStatisticDto>, options?: any): AxiosPromise<void> {
            return localVarFp.healthDataControllerCreateQuantityStatistic(createQuantityStatisticDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateHealthDataUserDto} updateHealthDataUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthDataControllerUpdateHealthDataUser(updateHealthDataUserDto: UpdateHealthDataUserDto, options?: any): AxiosPromise<HealthDataUser> {
            return localVarFp.healthDataControllerUpdateHealthDataUser(updateHealthDataUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthDataApi - object-oriented interface
 * @export
 * @class HealthDataApi
 * @extends {BaseAPI}
 */
export class HealthDataApi extends BaseAPI {
    /**
     * 
     * @param {CreateCategorySampleDto} createCategorySampleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthDataApi
     */
    public healthDataControllerCreateCategorySample(createCategorySampleDto: CreateCategorySampleDto, options?: AxiosRequestConfig) {
        return HealthDataApiFp(this.configuration).healthDataControllerCreateCategorySample(createCategorySampleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateCategorySampleDto>} createCategorySampleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthDataApi
     */
    public healthDataControllerCreateCategorySampleV2(createCategorySampleDto: Array<CreateCategorySampleDto>, options?: AxiosRequestConfig) {
        return HealthDataApiFp(this.configuration).healthDataControllerCreateCategorySampleV2(createCategorySampleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateQuantitySampleDto>} createQuantitySampleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthDataApi
     */
    public healthDataControllerCreateQuantitySample(createQuantitySampleDto: Array<CreateQuantitySampleDto>, options?: AxiosRequestConfig) {
        return HealthDataApiFp(this.configuration).healthDataControllerCreateQuantitySample(createQuantitySampleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateQuantityStatisticDto>} createQuantityStatisticDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthDataApi
     */
    public healthDataControllerCreateQuantityStatistic(createQuantityStatisticDto: Array<CreateQuantityStatisticDto>, options?: AxiosRequestConfig) {
        return HealthDataApiFp(this.configuration).healthDataControllerCreateQuantityStatistic(createQuantityStatisticDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateHealthDataUserDto} updateHealthDataUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthDataApi
     */
    public healthDataControllerUpdateHealthDataUser(updateHealthDataUserDto: UpdateHealthDataUserDto, options?: AxiosRequestConfig) {
        return HealthDataApiFp(this.configuration).healthDataControllerUpdateHealthDataUser(updateHealthDataUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthServiceApi - axios parameter creator
 * @export
 */
export const HealthServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateHealthServiceDto} createHealthServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerCreate: async (createHealthServiceDto: CreateHealthServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHealthServiceDto' is not null or undefined
            assertParamExists('healthServiceControllerCreate', 'createHealthServiceDto', createHealthServiceDto)
            const localVarPath = `/v1/health-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHealthServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateServiceInterestDto} createServiceInterestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerCreateInterest: async (createServiceInterestDto: CreateServiceInterestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceInterestDto' is not null or undefined
            assertParamExists('healthServiceControllerCreateInterest', 'createServiceInterestDto', createServiceInterestDto)
            const localVarPath = `/v1/health-service/interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceInterestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthServiceControllerDelete', 'id', id)
            const localVarPath = `/v1/health-service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>} [occupationAreaType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerFindAll: async (limit: number, offset: number, order?: string, title?: string, description?: string, occupationAreaType?: Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('healthServiceControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('healthServiceControllerFindAll', 'offset', offset)
            const localVarPath = `/v1/health-service`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (occupationAreaType) {
                localVarQueryParameter['occupationAreaType'] = occupationAreaType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [userId] 
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerFindAllInterests: async (limit: number, offset: number, order?: string, userId?: string, accountId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('healthServiceControllerFindAllInterests', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('healthServiceControllerFindAllInterests', 'offset', offset)
            const localVarPath = `/v1/health-service/interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthServiceControllerFindOne', 'id', id)
            const localVarPath = `/v1/health-service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHealthServiceDto} updateHealthServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerUpdate: async (id: string, updateHealthServiceDto: UpdateHealthServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('healthServiceControllerUpdate', 'id', id)
            // verify required parameter 'updateHealthServiceDto' is not null or undefined
            assertParamExists('healthServiceControllerUpdate', 'updateHealthServiceDto', updateHealthServiceDto)
            const localVarPath = `/v1/health-service/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHealthServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthServiceApi - functional programming interface
 * @export
 */
export const HealthServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateHealthServiceDto} createHealthServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerCreate(createHealthServiceDto: CreateHealthServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerCreate(createHealthServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateServiceInterestDto} createServiceInterestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerCreateInterest(createServiceInterestDto: CreateServiceInterestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceInterestDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerCreateInterest(createServiceInterestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>} [occupationAreaType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerFindAll(limit: number, offset: number, order?: string, title?: string, description?: string, occupationAreaType?: Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthServicePaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerFindAll(limit, offset, order, title, description, occupationAreaType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [userId] 
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerFindAllInterests(limit: number, offset: number, order?: string, userId?: string, accountId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceInterestPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerFindAllInterests(limit, offset, order, userId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHealthServiceDto} updateHealthServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthServiceControllerUpdate(id: string, updateHealthServiceDto: UpdateHealthServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthServiceControllerUpdate(id, updateHealthServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthServiceApi - factory interface
 * @export
 */
export const HealthServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateHealthServiceDto} createHealthServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerCreate(createHealthServiceDto: CreateHealthServiceDto, options?: any): AxiosPromise<HealthService> {
            return localVarFp.healthServiceControllerCreate(createHealthServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateServiceInterestDto} createServiceInterestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerCreateInterest(createServiceInterestDto: CreateServiceInterestDto, options?: any): AxiosPromise<Array<ServiceInterestDto>> {
            return localVarFp.healthServiceControllerCreateInterest(createServiceInterestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.healthServiceControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>} [occupationAreaType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerFindAll(limit: number, offset: number, order?: string, title?: string, description?: string, occupationAreaType?: Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>, options?: any): AxiosPromise<HealthServicePaginatedResultDto> {
            return localVarFp.healthServiceControllerFindAll(limit, offset, order, title, description, occupationAreaType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [userId] 
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerFindAllInterests(limit: number, offset: number, order?: string, userId?: string, accountId?: string, options?: any): AxiosPromise<ServiceInterestPaginatedResultDto> {
            return localVarFp.healthServiceControllerFindAllInterests(limit, offset, order, userId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerFindOne(id: string, options?: any): AxiosPromise<HealthService> {
            return localVarFp.healthServiceControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateHealthServiceDto} updateHealthServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthServiceControllerUpdate(id: string, updateHealthServiceDto: UpdateHealthServiceDto, options?: any): AxiosPromise<HealthService> {
            return localVarFp.healthServiceControllerUpdate(id, updateHealthServiceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthServiceApi - object-oriented interface
 * @export
 * @class HealthServiceApi
 * @extends {BaseAPI}
 */
export class HealthServiceApi extends BaseAPI {
    /**
     * 
     * @param {CreateHealthServiceDto} createHealthServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerCreate(createHealthServiceDto: CreateHealthServiceDto, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerCreate(createHealthServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateServiceInterestDto} createServiceInterestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerCreateInterest(createServiceInterestDto: CreateServiceInterestDto, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerCreateInterest(createServiceInterestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerDelete(id: string, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [title] 
     * @param {string} [description] 
     * @param {Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>} [occupationAreaType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerFindAll(limit: number, offset: number, order?: string, title?: string, description?: string, occupationAreaType?: Array<'general_health' | 'physical_therapy' | 'mental_health' | 'sleep_and_recovery' | 'nutrition'>, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerFindAll(limit, offset, order, title, description, occupationAreaType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [userId] 
     * @param {string} [accountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerFindAllInterests(limit: number, offset: number, order?: string, userId?: string, accountId?: string, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerFindAllInterests(limit, offset, order, userId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateHealthServiceDto} updateHealthServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthServiceApi
     */
    public healthServiceControllerUpdate(id: string, updateHealthServiceDto: UpdateHealthServiceDto, options?: AxiosRequestConfig) {
        return HealthServiceApiFp(this.configuration).healthServiceControllerUpdate(id, updateHealthServiceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicBaselineAssessmentApi - axios parameter creator
 * @export
 */
export const PublicBaselineAssessmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentControllerGetAssessmentResultsFromCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('publicBaselineAssessmentControllerGetAssessmentResultsFromCode', 'code', code)
            const localVarPath = `/v1/public/baseline-assessment/assessment-results/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentControllerGetBaselineQuestionById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicBaselineAssessmentControllerGetBaselineQuestionById', 'id', id)
            const localVarPath = `/v1/public/baseline-assessment/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentControllerGetBaselineQuestions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/public/baseline-assessment/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicBaselineAssessmentApi - functional programming interface
 * @export
 */
export const PublicBaselineAssessmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicBaselineAssessmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBaselineAssessmentControllerGetAssessmentResultsFromCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociateBaselineAssessmentAnswersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBaselineAssessmentControllerGetAssessmentResultsFromCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBaselineAssessmentControllerGetBaselineQuestionById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBaselineAssessmentControllerGetBaselineQuestionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBaselineAssessmentControllerGetBaselineQuestions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBaselineAssessmentControllerGetBaselineQuestions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicBaselineAssessmentApi - factory interface
 * @export
 */
export const PublicBaselineAssessmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicBaselineAssessmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentControllerGetAssessmentResultsFromCode(code: string, options?: any): AxiosPromise<AssociateBaselineAssessmentAnswersDto> {
            return localVarFp.publicBaselineAssessmentControllerGetAssessmentResultsFromCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentControllerGetBaselineQuestionById(id: number, options?: any): AxiosPromise<QuestionDto> {
            return localVarFp.publicBaselineAssessmentControllerGetBaselineQuestionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentControllerGetBaselineQuestions(options?: any): AxiosPromise<Array<QuestionDto>> {
            return localVarFp.publicBaselineAssessmentControllerGetBaselineQuestions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicBaselineAssessmentApi - object-oriented interface
 * @export
 * @class PublicBaselineAssessmentApi
 * @extends {BaseAPI}
 */
export class PublicBaselineAssessmentApi extends BaseAPI {
    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBaselineAssessmentApi
     */
    public publicBaselineAssessmentControllerGetAssessmentResultsFromCode(code: string, options?: AxiosRequestConfig) {
        return PublicBaselineAssessmentApiFp(this.configuration).publicBaselineAssessmentControllerGetAssessmentResultsFromCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBaselineAssessmentApi
     */
    public publicBaselineAssessmentControllerGetBaselineQuestionById(id: number, options?: AxiosRequestConfig) {
        return PublicBaselineAssessmentApiFp(this.configuration).publicBaselineAssessmentControllerGetBaselineQuestionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBaselineAssessmentApi
     */
    public publicBaselineAssessmentControllerGetBaselineQuestions(options?: AxiosRequestConfig) {
        return PublicBaselineAssessmentApiFp(this.configuration).publicBaselineAssessmentControllerGetBaselineQuestions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicBaselineAssessmentAnswerApi - axios parameter creator
 * @export
 */
export const PublicBaselineAssessmentAnswerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentAnswerControllerCreateAll: async (createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAllBaselineAssessmentAnswersDto' is not null or undefined
            assertParamExists('publicBaselineAssessmentAnswerControllerCreateAll', 'createAllBaselineAssessmentAnswersDto', createAllBaselineAssessmentAnswersDto)
            const localVarPath = `/v1/public/baseline-assessment-answer/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAllBaselineAssessmentAnswersDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicBaselineAssessmentAnswerApi - functional programming interface
 * @export
 */
export const PublicBaselineAssessmentAnswerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicBaselineAssessmentAnswerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBaselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssociateBaselineAssessmentAnswersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBaselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicBaselineAssessmentAnswerApi - factory interface
 * @export
 */
export const PublicBaselineAssessmentAnswerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicBaselineAssessmentAnswerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options?: any): AxiosPromise<AssociateBaselineAssessmentAnswersDto> {
            return localVarFp.publicBaselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicBaselineAssessmentAnswerApi - object-oriented interface
 * @export
 * @class PublicBaselineAssessmentAnswerApi
 * @extends {BaseAPI}
 */
export class PublicBaselineAssessmentAnswerApi extends BaseAPI {
    /**
     * 
     * @param {CreateAllBaselineAssessmentAnswersDto} createAllBaselineAssessmentAnswersDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBaselineAssessmentAnswerApi
     */
    public publicBaselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto: CreateAllBaselineAssessmentAnswersDto, options?: AxiosRequestConfig) {
        return PublicBaselineAssessmentAnswerApiFp(this.configuration).publicBaselineAssessmentAnswerControllerCreateAll(createAllBaselineAssessmentAnswersDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicBaselineAssessmentWaitlistApi - axios parameter creator
 * @export
 */
export const PublicBaselineAssessmentWaitlistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBaselineAssessmentWaitlistDto} createBaselineAssessmentWaitlistDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentWaitlistControllerCreate: async (createBaselineAssessmentWaitlistDto: CreateBaselineAssessmentWaitlistDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBaselineAssessmentWaitlistDto' is not null or undefined
            assertParamExists('publicBaselineAssessmentWaitlistControllerCreate', 'createBaselineAssessmentWaitlistDto', createBaselineAssessmentWaitlistDto)
            const localVarPath = `/v1/public/baseline-assessment-waitlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBaselineAssessmentWaitlistDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicBaselineAssessmentWaitlistApi - functional programming interface
 * @export
 */
export const PublicBaselineAssessmentWaitlistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicBaselineAssessmentWaitlistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBaselineAssessmentWaitlistDto} createBaselineAssessmentWaitlistDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBaselineAssessmentWaitlistControllerCreate(createBaselineAssessmentWaitlistDto: CreateBaselineAssessmentWaitlistDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaselineAssessmentWaitlistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBaselineAssessmentWaitlistControllerCreate(createBaselineAssessmentWaitlistDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicBaselineAssessmentWaitlistApi - factory interface
 * @export
 */
export const PublicBaselineAssessmentWaitlistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicBaselineAssessmentWaitlistApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBaselineAssessmentWaitlistDto} createBaselineAssessmentWaitlistDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBaselineAssessmentWaitlistControllerCreate(createBaselineAssessmentWaitlistDto: CreateBaselineAssessmentWaitlistDto, options?: any): AxiosPromise<BaselineAssessmentWaitlistDto> {
            return localVarFp.publicBaselineAssessmentWaitlistControllerCreate(createBaselineAssessmentWaitlistDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicBaselineAssessmentWaitlistApi - object-oriented interface
 * @export
 * @class PublicBaselineAssessmentWaitlistApi
 * @extends {BaseAPI}
 */
export class PublicBaselineAssessmentWaitlistApi extends BaseAPI {
    /**
     * 
     * @param {CreateBaselineAssessmentWaitlistDto} createBaselineAssessmentWaitlistDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBaselineAssessmentWaitlistApi
     */
    public publicBaselineAssessmentWaitlistControllerCreate(createBaselineAssessmentWaitlistDto: CreateBaselineAssessmentWaitlistDto, options?: AxiosRequestConfig) {
        return PublicBaselineAssessmentWaitlistApiFp(this.configuration).publicBaselineAssessmentWaitlistControllerCreate(createBaselineAssessmentWaitlistDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoryApi - axios parameter creator
 * @export
 */
export const StoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyConfigurationControllerCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/story-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyConfigurationControllerGetLast: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/story-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyConfigurationControllerGetLastV2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/story-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CreateStoryQuestionDto>} createStoryQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyQuestionControllerCreate: async (createStoryQuestionDto: Array<CreateStoryQuestionDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createStoryQuestionDto' is not null or undefined
            assertParamExists('storyQuestionControllerCreate', 'createStoryQuestionDto', createStoryQuestionDto)
            const localVarPath = `/v1/story-question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createStoryQuestionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyQuestionControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/story-question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoryApi - functional programming interface
 * @export
 */
export const StoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storyConfigurationControllerCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storyConfigurationControllerCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storyConfigurationControllerGetLast(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storyConfigurationControllerGetLast(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storyConfigurationControllerGetLastV2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoryConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storyConfigurationControllerGetLastV2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CreateStoryQuestionDto>} createStoryQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storyQuestionControllerCreate(createStoryQuestionDto: Array<CreateStoryQuestionDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoryQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storyQuestionControllerCreate(createStoryQuestionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storyQuestionControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StoryQuestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storyQuestionControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoryApi - factory interface
 * @export
 */
export const StoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyConfigurationControllerCreate(options?: any): AxiosPromise<StoryConfigurationDto> {
            return localVarFp.storyConfigurationControllerCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyConfigurationControllerGetLast(options?: any): AxiosPromise<StoryConfigurationDto> {
            return localVarFp.storyConfigurationControllerGetLast(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyConfigurationControllerGetLastV2(options?: any): AxiosPromise<StoryConfigurationDto> {
            return localVarFp.storyConfigurationControllerGetLastV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<CreateStoryQuestionDto>} createStoryQuestionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyQuestionControllerCreate(createStoryQuestionDto: Array<CreateStoryQuestionDto>, options?: any): AxiosPromise<Array<StoryQuestion>> {
            return localVarFp.storyQuestionControllerCreate(createStoryQuestionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storyQuestionControllerGetAll(options?: any): AxiosPromise<Array<StoryQuestion>> {
            return localVarFp.storyQuestionControllerGetAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoryApi - object-oriented interface
 * @export
 * @class StoryApi
 * @extends {BaseAPI}
 */
export class StoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public storyConfigurationControllerCreate(options?: AxiosRequestConfig) {
        return StoryApiFp(this.configuration).storyConfigurationControllerCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public storyConfigurationControllerGetLast(options?: AxiosRequestConfig) {
        return StoryApiFp(this.configuration).storyConfigurationControllerGetLast(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public storyConfigurationControllerGetLastV2(options?: AxiosRequestConfig) {
        return StoryApiFp(this.configuration).storyConfigurationControllerGetLastV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<CreateStoryQuestionDto>} createStoryQuestionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public storyQuestionControllerCreate(createStoryQuestionDto: Array<CreateStoryQuestionDto>, options?: AxiosRequestConfig) {
        return StoryApiFp(this.configuration).storyQuestionControllerCreate(createStoryQuestionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoryApi
     */
    public storyQuestionControllerGetAll(options?: AxiosRequestConfig) {
        return StoryApiFp(this.configuration).storyQuestionControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }
}


