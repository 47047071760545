import { orderBy } from 'lodash';

import {
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  iconPath,
  notificationNewChatQueueBody,
  notificationNewMessage,
  notificationTitle,
  urlToQueue,
  urlToOpenChat,
} from '../../../../../contexts/notification/constants';

import { useNotification } from '../../../../../hooks/useNotification';

import useAppSelector from '../../../../../hooks/useAppSelector';
import useSocket from '../../../../../hooks/useSocket';
import useSocketEvent from '../../../../../hooks/useSocketEvent';
import { baseURL } from '../../../../../services/api/client';

import {
  QueueEntryDto,
  QueueEntryDtoStatusEnum,
  QueueQueueTypeEnum,
} from '../../../../../services/api/queue-api';

import { UserUserTypeEnum } from '../../../../../services/api/user-api';
import useQueueEntries from '../../../../../services/queries/useQueueEntries';
import { beforeDate, afterDate } from '../../../../../utils/help';
import { IQueueContext, QueueContextProviderProps } from './QueueContext';

import {
  queueEntriesLimit,
  queueEntriesOffset,
} from './QueueContext.constants';

import useErrors from '../../../../../utils/errors/useErrors';

import {
  OPEN_CHATS_QUEUE_ENTRY_STATUS,
  PHYSICIAN_TODAY_QUEUE_ENTRY_STATUS,
  SCHEDULED_ASSIGNED_QUEUE_ENTRY_STATUS,
  SCHEDULED_NOT_ASSIGNED_QUEUE_ENTRY_STATUS,
} from './constants';

import { getNamedNotificationBody } from './utils';

export const QueueContext = createContext<IQueueContext>({} as IQueueContext);

export function QueueContextProvider({ children }: QueueContextProviderProps) {
  const { activeAccountId } = useAppSelector((state) => ({
    activeAccountId: state.trackerboard.activeQueueEntry?.account?.id,
  }));

  const { id: meId, userType } = useAppSelector(({ me }) => me);

  const isPhysician = useMemo(() => (
    userType === UserUserTypeEnum.Physician
  ), [userType]);

  const { sendNotification } = useNotification();

  const [pendingQueueEntries, setPendingQueueEntries] = useState<Array<QueueEntryDto>>([]);
  const [assignedQueueEntries, setAssignedQueueEntries] = useState<Array<QueueEntryDto>>([]);
  const [scheduledQueueEntries, setScheduledQueueEntries] = useState<Array<QueueEntryDto>>([]);

  const [
    scheduledQueueEntriesWithoutCoach,
    setScheduledQueueEntriesWithoutCoach,
  ] = useState<Array<QueueEntryDto>>([]);

  const socket = useSocket(baseURL, '/queue/socket.io');

  const { showErrorMessage } = useErrors();

  const {
    isLoading: isLoadingAssignedQueueEntries,
    refetch: refetchAssignedQueueEntries,
  } = useQueueEntries(
    {
      limit: queueEntriesLimit,
      offset: queueEntriesOffset,
      order: '-updatedAt',
      status: ['assigned', 'in_progress'],
      queueType: 'general',
      coachId: isPhysician ? undefined : meId!,
      physicianId: isPhysician ? meId! : undefined,
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!meId,
      onSuccess({ pages }) {
        const queueEntries = pages.flatMap((page) => page.data.results) ?? [];
        setAssignedQueueEntries(queueEntries);
      },
    },
  );

  const {
    isLoading: isLoadingPendingQueueEntries,
    hasNextPage: hasNextPagePendingQueueEntries,
    fetchNextPage: fetchNextPagePendingQueueEntries,
    refetch: refetchPendingQueueEntries,
  } = useQueueEntries(
    {
      limit: queueEntriesLimit,
      offset: queueEntriesOffset,
      order: '-updatedAt',
      status: ['pending'],
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!meId,
      getNextPageParam: ({ data: result }) => {
        if (result.results.length >= result.limit) {
          return result.offset + result.limit;
        }
        return undefined;
      },
      onSuccess({ pages }) {
        const queueEntries = pages.flatMap((page) => page.data.results) ?? [];
        setPendingQueueEntries(queueEntries);
      },
      onError(error) {
        showErrorMessage(error);
      },
    },
  );

  const {
    isLoading: isLoadingScheduled,
    refetch: refetchScheduledQueueEntries,
  } = useQueueEntries(
    {
      limit: queueEntriesLimit,
      offset: queueEntriesOffset,
      order: '-scheduledForStart',
      status: !isPhysician ? ['assigned', 'in_progress'] : undefined,
      queueType: 'scheduled',
      scheduledForStartAfter: afterDate,
      scheduledForStartBefore: beforeDate,
      coachId: !isPhysician ? meId! : undefined,
      physicianId: !isPhysician ? undefined : meId!,
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!meId,
      onSuccess({ pages }) {
        const queueEntries = pages.flatMap((page) => page.data.results) ?? [];
        setScheduledQueueEntries(queueEntries);
      },
    },
  );

  const {
    isLoading: isLoadingScheduledWithoutCoach,
    refetch: refetchScheduledQueueEntriesWithoutCoach,
  } = useQueueEntries(
    {
      limit: queueEntriesLimit,
      offset: queueEntriesOffset,
      order: '-scheduledForStart',
      status: ['scheduled'],
      queueType: 'scheduled',
      hasNoCoach: true,
      scheduledForStartAfter: afterDate,
      scheduledForStartBefore: beforeDate,
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!meId,
      onSuccess({ pages }) {
        const queueEntries = pages.flatMap((page) => page.data.results) ?? [];
        setScheduledQueueEntriesWithoutCoach(queueEntries);
      },
    },
  );

  const isActiveQueueEntry = useCallback((item: string | undefined) => (
    item === activeAccountId
  ), [activeAccountId]);

  const isQueueTypeScheduled = useCallback((item: QueueEntryDto) => (
    item.queue.queueType === 'scheduled'
      && item.scheduledForStart >= afterDate
      && item.scheduledForEnd <= beforeDate
  ), []);

  const sendNewMessageNotification = useCallback((queueEntry: QueueEntryDto) => {
    const body = getNamedNotificationBody(notificationNewMessage, queueEntry);

    sendNotification(
      queueEntry.id,
      notificationTitle,
      { body, icon: iconPath },
      urlToOpenChat,
    );
  }, [sendNotification]);

  const sendNewChatNotification = useCallback((queueEntry: QueueEntryDto) => {
    const body = getNamedNotificationBody(notificationNewChatQueueBody, queueEntry);

    sendNotification(
      queueEntry.id,
      notificationTitle,
      { body, icon: iconPath },
      urlToQueue,
    );
  }, [sendNotification]);

  const updateState = useCallback((
    stateFunction: React.Dispatch<React.SetStateAction<QueueEntryDto[]>>,
    newQueueEntry: QueueEntryDto,
    order: string,
    isIncluded: boolean,
  ) => {
    stateFunction((oldState: QueueEntryDto[]) => {
      // Try to remove queue entry from current list
      let filteredQueueEntries = oldState.filter(
        (queueEntry) => queueEntry.id !== newQueueEntry.id
          && queueEntry.account?.id !== newQueueEntry.account?.id,
      );

      if (isIncluded) {
        filteredQueueEntries = [...filteredQueueEntries, newQueueEntry];
      }

      return orderBy(filteredQueueEntries, order, 'desc');
    });
  }, []);

  const updateQueueEntries = useCallback((newQueueEntry: QueueEntryDto) => {
    const {
      coachId,
      physicianId,
      previousCoachId,
      lastMessageUser,
      status,
    } = newQueueEntry;

    const isAssignedToCurrentUser = coachId === meId || physicianId === meId;
    const wasPreviouslyAssignedToCurrentUser = previousCoachId === meId;
    const wasLastMessageFromCurrentUser = lastMessageUser?.id === meId;

    // Open chats/Assigned
    if (newQueueEntry.queue.queueType !== QueueQueueTypeEnum.Scheduled && isAssignedToCurrentUser) {
      // Only include new queue entry if it matches list allowed states
      // And should be assigned to logged in coach user
      const isIncluded = OPEN_CHATS_QUEUE_ENTRY_STATUS.includes(status);
      updateState(setAssignedQueueEntries, newQueueEntry, 'updatedAt', isIncluded);

      const previousLastMessageTimestamp = assignedQueueEntries.find((queueEntry) => (
        queueEntry.id === newQueueEntry.id
      ))?.lastMessageTimestamp;

      if (isIncluded && !wasLastMessageFromCurrentUser && !!previousLastMessageTimestamp) {
        sendNewMessageNotification(newQueueEntry);
      }
    }

    // Upcoming scheduled assigned
    if (!isPhysician && isQueueTypeScheduled(newQueueEntry)) {
      const isIncluded = SCHEDULED_ASSIGNED_QUEUE_ENTRY_STATUS.includes(newQueueEntry.status);
      updateState(setScheduledQueueEntries, newQueueEntry, 'scheduledForStart', isIncluded);
    }

    // Today Physician tab update
    if (isPhysician && isActiveQueueEntry(newQueueEntry.id)) {
      const isIncluded = PHYSICIAN_TODAY_QUEUE_ENTRY_STATUS.includes(newQueueEntry.status);
      updateState(setScheduledQueueEntries, newQueueEntry, 'scheduledForStart', isIncluded);
    }

    // Scheduled appointments not assigned
    if (isQueueTypeScheduled(newQueueEntry)) {
      const isIncluded = SCHEDULED_NOT_ASSIGNED_QUEUE_ENTRY_STATUS.includes(newQueueEntry.status);

      updateState(
        setScheduledQueueEntriesWithoutCoach,
        newQueueEntry,
        'scheduledForStart',
        isIncluded,
      );
    }

    // Pending tab update
    if (newQueueEntry.queue.queueType !== QueueQueueTypeEnum.Scheduled) {
      const isIncluded = newQueueEntry.status === QueueEntryDtoStatusEnum.Pending;
      updateState(setPendingQueueEntries, newQueueEntry, 'updatedAt', isIncluded);

      if (
        isIncluded
        && !wasPreviouslyAssignedToCurrentUser
        && !wasLastMessageFromCurrentUser
      ) {
        sendNewChatNotification(newQueueEntry);
      }
    }
  }, [
    meId,
    assignedQueueEntries,
    isPhysician,
    isQueueTypeScheduled,
    isActiveQueueEntry,
    updateState,
    sendNewMessageNotification,
    sendNewChatNotification,
  ]);

  useSocketEvent(socket, 'queue-entry', updateQueueEntries, [socket, assignedQueueEntries]);

  const refetchQueueEntries = useCallback(() => {
    refetchAssignedQueueEntries();
    refetchPendingQueueEntries();
    refetchScheduledQueueEntries();
    refetchScheduledQueueEntriesWithoutCoach();
  }, [
    refetchAssignedQueueEntries,
    refetchPendingQueueEntries,
    refetchScheduledQueueEntries,
    refetchScheduledQueueEntriesWithoutCoach,
  ]);

  const value: IQueueContext = useMemo(
    () => ({
      pendingQueueEntries,
      scheduledQueueEntries,
      scheduledQueueEntriesWithoutCoach,
      isLoadingScheduled,
      isLoadingAssignedQueueEntries,
      isLoading: isLoadingPendingQueueEntries,
      isLoadingScheduledWithoutCoach,
      assignedQueueEntries,
      hasNextPagePendingQueueEntries,
      fetchNextPagePendingQueueEntries,
      refetchQueueEntries,
    }),
    [
      pendingQueueEntries,
      scheduledQueueEntries,
      scheduledQueueEntriesWithoutCoach,
      isLoadingScheduled,
      isLoadingAssignedQueueEntries,
      isLoadingPendingQueueEntries,
      assignedQueueEntries,
      isLoadingScheduledWithoutCoach,
      hasNextPagePendingQueueEntries,
      fetchNextPagePendingQueueEntries,
      refetchQueueEntries,
    ],
  );

  return (
    <QueueContext.Provider value={value}>{children}</QueueContext.Provider>
  );
}
