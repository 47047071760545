/* tslint:disable */
/* eslint-disable */
/**
 * Sunrise Queue API
 * Queue management API.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     *
     * @type {string}
     * @memberof AccountDto
     */
    'id': string;
    /**
     *
     * @type {UserDto}
     * @memberof AccountDto
     */
    'owner': UserDto;
    /**
     *
     * @type {Array<MembershipDto>}
     * @memberof AccountDto
     */
    'memberships': Array<MembershipDto>;
    /**
     *
     * @type {Array<InvitationDto>}
     * @memberof AccountDto
     */
    'invitations': Array<InvitationDto>;
}
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof Category
     */
    'deletedAt': string;
    /**
     *
     * @type {CategoryType}
     * @memberof Category
     */
    'categoryType': CategoryType;
    /**
     *
     * @type {Array<CommunityPost>}
     * @memberof Category
     */
    'communityPosts': Array<CommunityPost>;
    /**
     *
     * @type {Array<MarketplaceItem>}
     * @memberof Category
     */
    'marketplaceItems': Array<MarketplaceItem>;
}
/**
 *
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     *
     * @type {string}
     * @memberof CategoryDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CategoryDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CategoryDto
     */
    'updatedAt': string;
    /**
     *
     * @type {CategoryType}
     * @memberof CategoryDto
     */
    'categoryType': CategoryType;
    /**
     *
     * @type {string}
     * @memberof CategoryDto
     */
    'categoryName': string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CategoryType {
    PhysicalHealth = 'physical_health',
    Nutrition = 'nutrition',
    EmotionalHealth = 'emotional_health',
    SleepAndRecovery = 'sleep_and_recovery',
    SocialConnection = 'social_connection',
    Appearance = 'appearance',
    HealthEconomics = 'health_economics',
    WomensMensHealth = 'womens_mens_health',
    Epigenetics = 'epigenetics',
    CognitiveHealth = 'cognitive_health',
    General = 'general'
}

/**
 *
 * @export
 * @interface CommunityAttachment
 */
export interface CommunityAttachment {
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'attachmentName': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'mimeType': string;
    /**
     *
     * @type {number}
     * @memberof CommunityAttachment
     */
    'attachmentSize': number;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'bucketName': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'key': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachment
     */
    'thumbnailKey'?: string;
    /**
     *
     * @type {CommunityPost}
     * @memberof CommunityAttachment
     */
    'communityPost': CommunityPost;
}
/**
 *
 * @export
 * @interface CommunityAttachmentDto
 */
export interface CommunityAttachmentDto {
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'attachmentName': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'mimeType': string;
    /**
     *
     * @type {number}
     * @memberof CommunityAttachmentDto
     */
    'attachmentSize': number;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'attachmentUrl': string;
    /**
     *
     * @type {string}
     * @memberof CommunityAttachmentDto
     */
    'thumbnailUrl'?: string;
}
/**
 *
 * @export
 * @interface CommunityItemReportDto
 */
export interface CommunityItemReportDto {
    /**
     *
     * @type {string}
     * @memberof CommunityItemReportDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityItemReportDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityItemReportDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityItemReportDto
     */
    'communityItemId': string;
    /**
     *
     * @type {CommunityItemReportType}
     * @memberof CommunityItemReportDto
     */
    'communityItemType': CommunityItemReportType;
    /**
     *
     * @type {CommunityItemReportReasonType}
     * @memberof CommunityItemReportDto
     */
    'reportReasonType': CommunityItemReportReasonType;
    /**
     *
     * @type {string}
     * @memberof CommunityItemReportDto
     */
    'reportedByUserId': string;
    /**
     *
     * @type {string}
     * @memberof CommunityItemReportDto
     */
    'reportReason': string;
    /**
     *
     * @type {CommunityPostDto}
     * @memberof CommunityItemReportDto
     */
    'communityPost'?: CommunityPostDto;
    /**
     *
     * @type {CommunityPostCommentsDto}
     * @memberof CommunityItemReportDto
     */
    'communityComment'?: CommunityPostCommentsDto;
}
/**
 *
 * @export
 * @interface CommunityItemReportPaginatedResultDto
 */
export interface CommunityItemReportPaginatedResultDto {
    /**
     *
     * @type {number}
     * @memberof CommunityItemReportPaginatedResultDto
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof CommunityItemReportPaginatedResultDto
     */
    'limit': number;
    /**
     *
     * @type {number}
     * @memberof CommunityItemReportPaginatedResultDto
     */
    'offset': number;
    /**
     *
     * @type {Array<CommunityItemReportDto>}
     * @memberof CommunityItemReportPaginatedResultDto
     */
    'results': Array<CommunityItemReportDto>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CommunityItemReportReasonType {
    SpamContent = 'spam_content',
    SexualContent = 'sexual_content',
    HateSpeech = 'hate_speech',
    FalseInformation = 'false_information',
    ViolenceContent = 'violence_content',
    IllegalSubstances = 'illegal_substances',
    SomethingElse = 'something_else'
}

/**
 *
 * @export
 * @enum {string}
 */

export enum CommunityItemReportType {
    Post = 'post',
    Comment = 'comment',
    Profile = 'profile'
}

/**
 *
 * @export
 * @interface CommunityKudos
 */
export interface CommunityKudos {
    /**
     *
     * @type {string}
     * @memberof CommunityKudos
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityKudos
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityKudos
     */
    'userId': string;
    /**
     *
     * @type {CommunityPost}
     * @memberof CommunityKudos
     */
    'communityPost': CommunityPost;
    /**
     *
     * @type {string}
     * @memberof CommunityKudos
     */
    'communityPostId'?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CommunityMemberType {
    Member = 'member',
    HelloFamTeam = 'hello-fam-team',
    KeyOpinionLeader = 'key-opinion-leader'
}

/**
 *
 * @export
 * @interface CommunityPost
 */
export interface CommunityPost {
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'deletedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'content': string;
    /**
     *
     * @type {CommunityPostStatus}
     * @memberof CommunityPost
     */
    'communityPostStatus': CommunityPostStatus;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'authorId': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'communityAttachmentId'?: string;
    /**
     *
     * @type {Array<CommunityKudos>}
     * @memberof CommunityPost
     */
    'communityKudos': Array<CommunityKudos>;
    /**
     *
     * @type {number}
     * @memberof CommunityPost
     */
    'kudosCount': number;
    /**
     *
     * @type {Array<Category>}
     * @memberof CommunityPost
     */
    'categories': Array<Category>;
    /**
     *
     * @type {CommunityPrompt}
     * @memberof CommunityPost
     */
    'communityPrompt': CommunityPrompt;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'communityPromptId'?: string;
    /**
     *
     * @type {object}
     * @memberof CommunityPost
     */
    'pinnedAt': object;
    /**
     *
     * @type {string}
     * @memberof CommunityPost
     */
    'postedByUserId'?: string;
    /**
     *
     * @type {Array<CommunityPostComments>}
     * @memberof CommunityPost
     */
    'postComments': Array<CommunityPostComments>;
    /**
     *
     * @type {number}
     * @memberof CommunityPost
     */
    'publicViewCount': number;
    /**
     *
     * @type {number}
     * @memberof CommunityPost
     */
    'internalViewCount': number;
}
/**
 *
 * @export
 * @interface CommunityPostComments
 */
export interface CommunityPostComments {
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'deletedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'content': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'postId': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostComments
     */
    'authorId': string;
    /**
     *
     * @type {CommunityPost}
     * @memberof CommunityPostComments
     */
    'post': CommunityPost;
}
/**
 *
 * @export
 * @interface CommunityPostCommentsDto
 */
export interface CommunityPostCommentsDto {
    /**
     *
     * @type {string}
     * @memberof CommunityPostCommentsDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostCommentsDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostCommentsDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostCommentsDto
     */
    'deletedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostCommentsDto
     */
    'content': string;
    /**
     *
     * @type {UserDto}
     * @memberof CommunityPostCommentsDto
     */
    'author': UserDto;
    /**
     *
     * @type {string}
     * @memberof CommunityPostCommentsDto
     */
    'postId': string;
}
/**
 *
 * @export
 * @interface CommunityPostCommentsPaginatedResultDto
 */
export interface CommunityPostCommentsPaginatedResultDto {
    /**
     *
     * @type {number}
     * @memberof CommunityPostCommentsPaginatedResultDto
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof CommunityPostCommentsPaginatedResultDto
     */
    'limit': number;
    /**
     *
     * @type {number}
     * @memberof CommunityPostCommentsPaginatedResultDto
     */
    'offset': number;
    /**
     *
     * @type {Array<CommunityPostCommentsDto>}
     * @memberof CommunityPostCommentsPaginatedResultDto
     */
    'results': Array<CommunityPostCommentsDto>;
}
/**
 *
 * @export
 * @interface CommunityPostDto
 */
export interface CommunityPostDto {
    /**
     *
     * @type {string}
     * @memberof CommunityPostDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostDto
     */
    'content': string;
    /**
     *
     * @type {object}
     * @memberof CommunityPostDto
     */
    'pinnedAt': object;
    /**
     *
     * @type {CommunityPostStatus}
     * @memberof CommunityPostDto
     */
    'communityPostStatus': CommunityPostStatus;
    /**
     *
     * @type {CommunityAttachmentDto}
     * @memberof CommunityPostDto
     */
    'communityAttachment'?: CommunityAttachmentDto;
    /**
     *
     * @type {number}
     * @memberof CommunityPostDto
     */
    'kudosCount': number;
    /**
     *
     * @type {boolean}
     * @memberof CommunityPostDto
     */
    'likedByCurrentUser'?: boolean;
    /**
     *
     * @type {UserDto}
     * @memberof CommunityPostDto
     */
    'author': UserDto;
    /**
     *
     * @type {number}
     * @memberof CommunityPostDto
     */
    'commentsCount': number;
    /**
     *
     * @type {boolean}
     * @memberof CommunityPostDto
     */
    'showCommunityHighlight'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CommunityPostDto
     */
    'verifiedAuthor'?: boolean;
    /**
     *
     * @type {CommunityPromptDto}
     * @memberof CommunityPostDto
     */
    'communityPrompt'?: CommunityPromptDto;
    /**
     *
     * @type {string}
     * @memberof CommunityPostDto
     */
    'postedByUserId'?: string;
    /**
     *
     * @type {string}
     * @memberof CommunityPostDto
     */
    'communityPublicPostUrl'?: string;
    /**
     *
     * @type {number}
     * @memberof CommunityPostDto
     */
    'publicViewCount': number;
    /**
     *
     * @type {number}
     * @memberof CommunityPostDto
     */
    'internalViewCount': number;
}
/**
 *
 * @export
 * @interface CommunityPostPaginatedResultDto
 */
export interface CommunityPostPaginatedResultDto {
    /**
     *
     * @type {number}
     * @memberof CommunityPostPaginatedResultDto
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof CommunityPostPaginatedResultDto
     */
    'limit': number;
    /**
     *
     * @type {number}
     * @memberof CommunityPostPaginatedResultDto
     */
    'offset': number;
    /**
     *
     * @type {Array<CommunityPostDto>}
     * @memberof CommunityPostPaginatedResultDto
     */
    'results': Array<CommunityPostDto>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum CommunityPostStatus {
    Pending = 'pending',
    Approved = 'approved',
    Suspended = 'suspended'
}

/**
 *
 * @export
 * @interface CommunityPrompt
 */
export interface CommunityPrompt {
    /**
     *
     * @type {string}
     * @memberof CommunityPrompt
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPrompt
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPrompt
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPrompt
     */
    'deletedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPrompt
     */
    'prompt': string;
    /**
     *
     * @type {Array<CommunityPost>}
     * @memberof CommunityPrompt
     */
    'communityPosts': Array<CommunityPost>;
}
/**
 *
 * @export
 * @interface CommunityPromptDto
 */
export interface CommunityPromptDto {
    /**
     *
     * @type {string}
     * @memberof CommunityPromptDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPromptDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPromptDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPromptDto
     */
    'prompt': string;
    /**
     *
     * @type {number}
     * @memberof CommunityPromptDto
     */
    'promptThreshold': number;
}
/**
 *
 * @export
 * @interface CommunityPublicPostDto
 */
export interface CommunityPublicPostDto {
    /**
     *
     * @type {CommunityPost}
     * @memberof CommunityPublicPostDto
     */
    'communityPost': CommunityPost;
    /**
     *
     * @type {UserDto}
     * @memberof CommunityPublicPostDto
     */
    'author': UserDto;
    /**
     *
     * @type {Array<CommunityPostCommentsDto>}
     * @memberof CommunityPublicPostDto
     */
    'comments': Array<CommunityPostCommentsDto>;
    /**
     *
     * @type {string}
     * @memberof CommunityPublicPostDto
     */
    'communityPostId': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPublicPostDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityPublicPostDto
     */
    'content': string;
    /**
     *
     * @type {CommunityAttachmentDto}
     * @memberof CommunityPublicPostDto
     */
    'communityAttachment': CommunityAttachmentDto;
}
/**
 *
 * @export
 * @interface CommunityUserBanDto
 */
export interface CommunityUserBanDto {
    /**
     *
     * @type {string}
     * @memberof CommunityUserBanDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof CommunityUserBanDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityUserBanDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof CommunityUserBanDto
     */
    'reason': string;
    /**
     *
     * @type {string}
     * @memberof CommunityUserBanDto
     */
    'bannedByUserId': string;
    /**
     *
     * @type {UserDto}
     * @memberof CommunityUserBanDto
     */
    'bannedUser': UserDto;
}
/**
 *
 * @export
 * @interface CommunityUserBanPaginatedResultDto
 */
export interface CommunityUserBanPaginatedResultDto {
    /**
     *
     * @type {number}
     * @memberof CommunityUserBanPaginatedResultDto
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof CommunityUserBanPaginatedResultDto
     */
    'limit': number;
    /**
     *
     * @type {number}
     * @memberof CommunityUserBanPaginatedResultDto
     */
    'offset': number;
    /**
     *
     * @type {Array<CommunityUserBanDto>}
     * @memberof CommunityUserBanPaginatedResultDto
     */
    'results': Array<CommunityUserBanDto>;
}
/**
 *
 * @export
 * @interface CreateCommunityItemReportDto
 */
export interface CreateCommunityItemReportDto {
    /**
     *
     * @type {string}
     * @memberof CreateCommunityItemReportDto
     */
    'communityItemId': string;
    /**
     *
     * @type {CommunityItemReportReasonType}
     * @memberof CreateCommunityItemReportDto
     */
    'reportReasonType': CommunityItemReportReasonType;
    /**
     *
     * @type {CommunityItemReportType}
     * @memberof CreateCommunityItemReportDto
     */
    'communityItemType': CommunityItemReportType;
}
/**
 *
 * @export
 * @interface CreateCommunityKudosDto
 */
export interface CreateCommunityKudosDto {
    /**
     *
     * @type {string}
     * @memberof CreateCommunityKudosDto
     */
    'communityPostId': string;
}
/**
 *
 * @export
 * @interface CreateCommunityPostCommentsDto
 */
export interface CreateCommunityPostCommentsDto {
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPostCommentsDto
     */
    'content'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPostCommentsDto
     */
    'postId'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPostCommentsDto
     */
    'authorId'?: string;
}
/**
 *
 * @export
 * @interface CreateCommunityPostDto
 */
export interface CreateCommunityPostDto {
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPostDto
     */
    'content'?: string;
    /**
     *
     * @type {CommunityAttachmentDto}
     * @memberof CreateCommunityPostDto
     */
    'communityAttachment'?: CommunityAttachmentDto;
    /**
     *
     * @type {Array<string>}
     * @memberof CreateCommunityPostDto
     */
    'categoryIds'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPostDto
     */
    'communityPromptId'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPostDto
     */
    'authorId'?: string;
}
/**
 *
 * @export
 * @interface CreateCommunityPromptDto
 */
export interface CreateCommunityPromptDto {
    /**
     *
     * @type {string}
     * @memberof CreateCommunityPromptDto
     */
    'prompt': string;
}
/**
 *
 * @export
 * @interface CreateCommunityUserBanDto
 */
export interface CreateCommunityUserBanDto {
    /**
     *
     * @type {string}
     * @memberof CreateCommunityUserBanDto
     */
    'reason': string;
    /**
     *
     * @type {string}
     * @memberof CreateCommunityUserBanDto
     */
    'userIdToBan': string;
}
/**
 *
 * @export
 * @interface CreateMarketplaceItemDto
 */
export interface CreateMarketplaceItemDto {
    /**
     *
     * @type {string}
     * @memberof CreateMarketplaceItemDto
     */
    'brand'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateMarketplaceItemDto
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateMarketplaceItemDto
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateMarketplaceItemDto
     */
    'price'?: string;
    /**
     *
     * @type {string}
     * @memberof CreateMarketplaceItemDto
     */
    'websiteLink'?: string;
    /**
     *
     * @type {MarketplaceAttachmentDto}
     * @memberof CreateMarketplaceItemDto
     */
    'marketplaceAttachment'?: MarketplaceAttachmentDto;
    /**
     *
     * @type {Array<string>}
     * @memberof CreateMarketplaceItemDto
     */
    'categoryIds'?: Array<string>;
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     *
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 *
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     *
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     *
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 *
 * @export
 * @interface InvitationDto
 */
export interface InvitationDto {
    /**
     *
     * @type {string}
     * @memberof InvitationDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof InvitationDto
     */
    'phoneCountryCode': string;
    /**
     *
     * @type {string}
     * @memberof InvitationDto
     */
    'invitedMobileNumber': string;
    /**
     *
     * @type {string}
     * @memberof InvitationDto
     */
    'status': string;
    /**
     *
     * @type {UserDto}
     * @memberof InvitationDto
     */
    'owner': UserDto;
    /**
     *
     * @type {string}
     * @memberof InvitationDto
     */
    'name': string;
}
/**
 *
 * @export
 * @interface IsCommunityPostDeletedDto
 */
export interface IsCommunityPostDeletedDto {
    /**
     *
     * @type {string}
     * @memberof IsCommunityPostDeletedDto
     */
    'communityPostId': string;
    /**
     *
     * @type {boolean}
     * @memberof IsCommunityPostDeletedDto
     */
    'isDeleted': boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum MarketPlaceItemTypeEnum {
    Product = 'product',
    Service = 'service'
}

/**
 *
 * @export
 * @interface MarketplaceAttachment
 */
export interface MarketplaceAttachment {
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'attachmentName': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'mimeType': string;
    /**
     *
     * @type {number}
     * @memberof MarketplaceAttachment
     */
    'attachmentSize': number;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'bucketName': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'key': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachment
     */
    'thumbnailKey'?: string;
    /**
     *
     * @type {MarketplaceItem}
     * @memberof MarketplaceAttachment
     */
    'marketplaceItem': MarketplaceItem;
}
/**
 *
 * @export
 * @interface MarketplaceAttachmentDto
 */
export interface MarketplaceAttachmentDto {
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'attachmentName': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'mimeType': string;
    /**
     *
     * @type {number}
     * @memberof MarketplaceAttachmentDto
     */
    'attachmentSize': number;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'attachmentUrl': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceAttachmentDto
     */
    'thumbnailUrl'?: string;
}
/**
 *
 * @export
 * @interface MarketplaceItem
 */
export interface MarketplaceItem {
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'deletedAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'brand': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'description': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'price': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'websiteLink': string;
    /**
     *
     * @type {MarketPlaceItemTypeEnum}
     * @memberof MarketplaceItem
     */
    'itemType': MarketPlaceItemTypeEnum;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItem
     */
    'marketplaceAttachmentId': string;
    /**
     *
     * @type {Array<Category>}
     * @memberof MarketplaceItem
     */
    'categories': Array<Category>;
}
/**
 *
 * @export
 * @interface MarketplaceItemDto
 */
export interface MarketplaceItemDto {
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'brand'?: string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'price'?: string;
    /**
     *
     * @type {string}
     * @memberof MarketplaceItemDto
     */
    'websiteLink'?: string;
    /**
     *
     * @type {MarketPlaceItemTypeEnum}
     * @memberof MarketplaceItemDto
     */
    'itemType': MarketPlaceItemTypeEnum;
    /**
     *
     * @type {MarketplaceAttachmentDto}
     * @memberof MarketplaceItemDto
     */
    'marketplaceAttachment'?: MarketplaceAttachmentDto;
}
/**
 *
 * @export
 * @interface MarketplaceItemPaginatedResultDto
 */
export interface MarketplaceItemPaginatedResultDto {
    /**
     *
     * @type {number}
     * @memberof MarketplaceItemPaginatedResultDto
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof MarketplaceItemPaginatedResultDto
     */
    'limit': number;
    /**
     *
     * @type {number}
     * @memberof MarketplaceItemPaginatedResultDto
     */
    'offset': number;
    /**
     *
     * @type {Array<MarketplaceItemDto>}
     * @memberof MarketplaceItemPaginatedResultDto
     */
    'results': Array<MarketplaceItemDto>;
}
/**
 *
 * @export
 * @interface MembershipDto
 */
export interface MembershipDto {
    /**
     *
     * @type {string}
     * @memberof MembershipDto
     */
    'id': string;
    /**
     *
     * @type {UserDto}
     * @memberof MembershipDto
     */
    'user': UserDto;
}
/**
 *
 * @export
 * @interface Queue
 */
export interface Queue {
    /**
     *
     * @type {string}
     * @memberof Queue
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof Queue
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof Queue
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof Queue
     */
    'queueType': QueueQueueTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueQueueTypeEnum {
    General = 'general',
    Scheduled = 'scheduled'
}

/**
 *
 * @export
 * @interface QueueEntry
 */
export interface QueueEntry {
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'updatedAt': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'deletedAt': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'accountId': string;
    /**
     *
     * @type {object}
     * @memberof QueueEntry
     */
    'coachId': object;
    /**
     *
     * @type {object}
     * @memberof QueueEntry
     */
    'previousCoachId': object;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'scheduledForStart'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'scheduledForEnd'?: string;
    /**
     *
     * @type {object}
     * @memberof QueueEntry
     */
    'physicianId'?: object;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'timezone'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'appointmentType'?: QueueEntryAppointmentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'status': QueueEntryStatusEnum;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'lastMessage': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'lastMessageTimestamp': string;
    /**
     *
     * @type {UserDto}
     * @memberof QueueEntry
     */
    'lastMessageUser': UserDto;
    /**
     *
     * @type {object}
     * @memberof QueueEntry
     */
    'lastCoachSeen': object;
    /**
     *
     * @type {string}
     * @memberof QueueEntry
     */
    'createdBy': string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}
/**
    * @export
    * @enum {string}
    */
export enum QueueEntryStatusEnum {
    Pending = 'pending',
    Scheduled = 'scheduled',
    Assigned = 'assigned',
    InProgress = 'in_progress',
    Completed = 'completed'
}

/**
 *
 * @export
 * @interface QueueEntryCreateDto
 */
export interface QueueEntryCreateDto {
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'scheduledForStart': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'scheduledForEnd': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'accountId': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'timezone': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'appointmentType': QueueEntryCreateDtoAppointmentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'coachId'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'physicianId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryCreateDtoAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 *
 * @export
 * @interface QueueEntryDto
 */
export interface QueueEntryDto {
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'createdAt': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'updatedAt': string;
    /**
     *
     * @type {Queue}
     * @memberof QueueEntryDto
     */
    'queue': Queue;
    /**
     *
     * @type {AccountDto}
     * @memberof QueueEntryDto
     */
    'account'?: AccountDto;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'coachId'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'previousCoachId'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'status': QueueEntryDtoStatusEnum;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'lastMessage'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'lastMessageTimestamp'?: string;
    /**
     *
     * @type {UserDto}
     * @memberof QueueEntryDto
     */
    'lastMessageUser'?: UserDto;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'scheduledForStart': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'scheduledForEnd': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'timezone': string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'appointmentType': QueueEntryDtoAppointmentTypeEnum;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'physicianId'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof QueueEntryDto
     */
    'createdBy'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryDtoStatusEnum {
    Pending = 'pending',
    Scheduled = 'scheduled',
    Assigned = 'assigned',
    InProgress = 'in_progress',
    Archived = 'archived'
}
/**
    * @export
    * @enum {string}
    */
export enum QueueEntryDtoAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 *
 * @export
 * @interface QueueEntryPaginatedResultDto
 */
export interface QueueEntryPaginatedResultDto {
    /**
     *
     * @type {number}
     * @memberof QueueEntryPaginatedResultDto
     */
    'total': number;
    /**
     *
     * @type {number}
     * @memberof QueueEntryPaginatedResultDto
     */
    'limit': number;
    /**
     *
     * @type {number}
     * @memberof QueueEntryPaginatedResultDto
     */
    'offset': number;
    /**
     *
     * @type {Array<QueueEntryDto>}
     * @memberof QueueEntryPaginatedResultDto
     */
    'results': Array<QueueEntryDto>;
}
/**
 *
 * @export
 * @interface UpdateCommunityPostCommentsDto
 */
export interface UpdateCommunityPostCommentsDto {
    /**
     *
     * @type {string}
     * @memberof UpdateCommunityPostCommentsDto
     */
    'content'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateCommunityPostCommentsDto
     */
    'postId'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateCommunityPostCommentsDto
     */
    'authorId'?: string;
}
/**
 *
 * @export
 * @interface UpdateCommunityPostDto
 */
export interface UpdateCommunityPostDto {
    /**
     *
     * @type {string}
     * @memberof UpdateCommunityPostDto
     */
    'content'?: string;
    /**
     *
     * @type {CommunityAttachmentDto}
     * @memberof UpdateCommunityPostDto
     */
    'communityAttachment'?: CommunityAttachmentDto;
    /**
     *
     * @type {Array<string>}
     * @memberof UpdateCommunityPostDto
     */
    'categoryIds'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UpdateCommunityPostDto
     */
    'communityPromptId'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateCommunityPostDto
     */
    'authorId'?: string;
}
/**
 *
 * @export
 * @interface UpdateCommunityPostStatusDto
 */
export interface UpdateCommunityPostStatusDto {
    /**
     *
     * @type {CommunityPostStatus}
     * @memberof UpdateCommunityPostStatusDto
     */
    'status': CommunityPostStatus;
}
/**
 *
 * @export
 * @interface UpdateMarketplaceItemDto
 */
export interface UpdateMarketplaceItemDto {
    /**
     *
     * @type {string}
     * @memberof UpdateMarketplaceItemDto
     */
    'brand'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateMarketplaceItemDto
     */
    'name'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateMarketplaceItemDto
     */
    'description'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateMarketplaceItemDto
     */
    'price'?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateMarketplaceItemDto
     */
    'websiteLink'?: string;
    /**
     *
     * @type {MarketplaceAttachmentDto}
     * @memberof UpdateMarketplaceItemDto
     */
    'marketplaceAttachment'?: MarketplaceAttachmentDto;
    /**
     *
     * @type {Array<string>}
     * @memberof UpdateMarketplaceItemDto
     */
    'categoryIds'?: Array<string>;
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'userType': UserDtoUserTypeEnum;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'phone': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'communityName': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'communityRole': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'communityBio': string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    'communityWebsiteLink': string;
    /**
     *
     * @type {CommunityMemberType}
     * @memberof UserDto
     */
    'communityMemberType': CommunityMemberType;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach',
    Assistant = 'assistant'
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryControllerGetAll(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.categoryControllerGetAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryControllerGetAll(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityAttachmentApi - axios parameter creator
 * @export
 */
export const CommunityAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityAttachmentControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityAttachmentControllerFindOne', 'id', id)
            const localVarPath = `/v1/community-attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityAttachmentControllerUploadAttachment: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/community-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityAttachmentApi - functional programming interface
 * @export
 */
export const CommunityAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityAttachmentControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityAttachmentControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityAttachmentControllerUploadAttachment(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityAttachmentControllerUploadAttachment(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityAttachmentApi - factory interface
 * @export
 */
export const CommunityAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityAttachmentApiFp(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityAttachmentControllerFindOne(id: string, options?: any): AxiosPromise<CommunityAttachmentDto> {
            return localVarFp.communityAttachmentControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityAttachmentControllerUploadAttachment(file?: any, options?: any): AxiosPromise<CommunityAttachmentDto> {
            return localVarFp.communityAttachmentControllerUploadAttachment(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityAttachmentApi - object-oriented interface
 * @export
 * @class CommunityAttachmentApi
 * @extends {BaseAPI}
 */
export class CommunityAttachmentApi extends BaseAPI {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityAttachmentApi
     */
    public communityAttachmentControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return CommunityAttachmentApiFp(this.configuration).communityAttachmentControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityAttachmentApi
     */
    public communityAttachmentControllerUploadAttachment(file?: any, options?: AxiosRequestConfig) {
        return CommunityAttachmentApiFp(this.configuration).communityAttachmentControllerUploadAttachment(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityItemReportApi - axios parameter creator
 * @export
 */
export const CommunityItemReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateCommunityItemReportDto} createCommunityItemReportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerCreate: async (createCommunityItemReportDto: CreateCommunityItemReportDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunityItemReportDto' is not null or undefined
            assertParamExists('communityItemReportControllerCreate', 'createCommunityItemReportDto', createCommunityItemReportDto)
            const localVarPath = `/v1/community-item-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunityItemReportDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityItemReportControllerDelete', 'id', id)
            const localVarPath = `/v1/community-item-report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [reportedByUserId]
         * @param {Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>} [reportReasonType]
         * @param {string} [communityItemId]
         * @param {Array<'post' | 'comment' | 'profile'>} [communityItemType]
         * @param {string} [createdAfter]
         * @param {string} [createdBefore]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerGetAll: async (limit: number, offset: number, order?: string, reportedByUserId?: string, reportReasonType?: Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>, communityItemId?: string, communityItemType?: Array<'post' | 'comment' | 'profile'>, createdAfter?: string, createdBefore?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('communityItemReportControllerGetAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('communityItemReportControllerGetAll', 'offset', offset)
            const localVarPath = `/v1/community-item-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (reportedByUserId !== undefined) {
                localVarQueryParameter['reportedByUserId'] = reportedByUserId;
            }

            if (reportReasonType) {
                localVarQueryParameter['reportReasonType'] = reportReasonType;
            }

            if (communityItemId !== undefined) {
                localVarQueryParameter['communityItemId'] = communityItemId;
            }

            if (communityItemType) {
                localVarQueryParameter['communityItemType'] = communityItemType;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = (createdAfter as any instanceof Date) ?
                    (createdAfter as any).toISOString() :
                    createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = (createdBefore as any instanceof Date) ?
                    (createdBefore as any).toISOString() :
                    createdBefore;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerGetOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityItemReportControllerGetOne', 'id', id)
            const localVarPath = `/v1/community-item-report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityItemReportApi - functional programming interface
 * @export
 */
export const CommunityItemReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityItemReportApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateCommunityItemReportDto} createCommunityItemReportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityItemReportControllerCreate(createCommunityItemReportDto: CreateCommunityItemReportDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityItemReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityItemReportControllerCreate(createCommunityItemReportDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityItemReportControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityItemReportControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [reportedByUserId]
         * @param {Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>} [reportReasonType]
         * @param {string} [communityItemId]
         * @param {Array<'post' | 'comment' | 'profile'>} [communityItemType]
         * @param {string} [createdAfter]
         * @param {string} [createdBefore]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityItemReportControllerGetAll(limit: number, offset: number, order?: string, reportedByUserId?: string, reportReasonType?: Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>, communityItemId?: string, communityItemType?: Array<'post' | 'comment' | 'profile'>, createdAfter?: string, createdBefore?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityItemReportPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityItemReportControllerGetAll(limit, offset, order, reportedByUserId, reportReasonType, communityItemId, communityItemType, createdAfter, createdBefore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityItemReportControllerGetOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityItemReportDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityItemReportControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityItemReportApi - factory interface
 * @export
 */
export const CommunityItemReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityItemReportApiFp(configuration)
    return {
        /**
         *
         * @param {CreateCommunityItemReportDto} createCommunityItemReportDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerCreate(createCommunityItemReportDto: CreateCommunityItemReportDto, options?: any): AxiosPromise<CommunityItemReportDto> {
            return localVarFp.communityItemReportControllerCreate(createCommunityItemReportDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityItemReportControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [reportedByUserId]
         * @param {Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>} [reportReasonType]
         * @param {string} [communityItemId]
         * @param {Array<'post' | 'comment' | 'profile'>} [communityItemType]
         * @param {string} [createdAfter]
         * @param {string} [createdBefore]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerGetAll(limit: number, offset: number, order?: string, reportedByUserId?: string, reportReasonType?: Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>, communityItemId?: string, communityItemType?: Array<'post' | 'comment' | 'profile'>, createdAfter?: string, createdBefore?: string, options?: any): AxiosPromise<CommunityItemReportPaginatedResultDto> {
            return localVarFp.communityItemReportControllerGetAll(limit, offset, order, reportedByUserId, reportReasonType, communityItemId, communityItemType, createdAfter, createdBefore, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityItemReportControllerGetOne(id: string, options?: any): AxiosPromise<CommunityItemReportDto> {
            return localVarFp.communityItemReportControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityItemReportApi - object-oriented interface
 * @export
 * @class CommunityItemReportApi
 * @extends {BaseAPI}
 */
export class CommunityItemReportApi extends BaseAPI {
    /**
     *
     * @param {CreateCommunityItemReportDto} createCommunityItemReportDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityItemReportApi
     */
    public communityItemReportControllerCreate(createCommunityItemReportDto: CreateCommunityItemReportDto, options?: AxiosRequestConfig) {
        return CommunityItemReportApiFp(this.configuration).communityItemReportControllerCreate(createCommunityItemReportDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityItemReportApi
     */
    public communityItemReportControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CommunityItemReportApiFp(this.configuration).communityItemReportControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [reportedByUserId]
     * @param {Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>} [reportReasonType]
     * @param {string} [communityItemId]
     * @param {Array<'post' | 'comment' | 'profile'>} [communityItemType]
     * @param {string} [createdAfter]
     * @param {string} [createdBefore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityItemReportApi
     */
    public communityItemReportControllerGetAll(limit: number, offset: number, order?: string, reportedByUserId?: string, reportReasonType?: Array<'spam_content' | 'sexual_content' | 'hate_speech' | 'false_information' | 'violence_content' | 'illegal_substances' | 'something_else'>, communityItemId?: string, communityItemType?: Array<'post' | 'comment' | 'profile'>, createdAfter?: string, createdBefore?: string, options?: AxiosRequestConfig) {
        return CommunityItemReportApiFp(this.configuration).communityItemReportControllerGetAll(limit, offset, order, reportedByUserId, reportReasonType, communityItemId, communityItemType, createdAfter, createdBefore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityItemReportApi
     */
    public communityItemReportControllerGetOne(id: string, options?: AxiosRequestConfig) {
        return CommunityItemReportApiFp(this.configuration).communityItemReportControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityKudosApi - axios parameter creator
 * @export
 */
export const CommunityKudosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateCommunityKudosDto} createCommunityKudosDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityKudosControllerCreate: async (createCommunityKudosDto: CreateCommunityKudosDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunityKudosDto' is not null or undefined
            assertParamExists('communityKudosControllerCreate', 'createCommunityKudosDto', createCommunityKudosDto)
            const localVarPath = `/v1/community-kudos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunityKudosDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityKudosControllerDelete: async (postId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('communityKudosControllerDelete', 'postId', postId)
            const localVarPath = `/v1/community-kudos/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityKudosApi - functional programming interface
 * @export
 */
export const CommunityKudosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityKudosApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateCommunityKudosDto} createCommunityKudosDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityKudosControllerCreate(createCommunityKudosDto: CreateCommunityKudosDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityKudosControllerCreate(createCommunityKudosDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityKudosControllerDelete(postId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityKudosControllerDelete(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityKudosApi - factory interface
 * @export
 */
export const CommunityKudosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityKudosApiFp(configuration)
    return {
        /**
         *
         * @param {CreateCommunityKudosDto} createCommunityKudosDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityKudosControllerCreate(createCommunityKudosDto: CreateCommunityKudosDto, options?: any): AxiosPromise<void> {
            return localVarFp.communityKudosControllerCreate(createCommunityKudosDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityKudosControllerDelete(postId: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityKudosControllerDelete(postId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityKudosApi - object-oriented interface
 * @export
 * @class CommunityKudosApi
 * @extends {BaseAPI}
 */
export class CommunityKudosApi extends BaseAPI {
    /**
     *
     * @param {CreateCommunityKudosDto} createCommunityKudosDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityKudosApi
     */
    public communityKudosControllerCreate(createCommunityKudosDto: CreateCommunityKudosDto, options?: AxiosRequestConfig) {
        return CommunityKudosApiFp(this.configuration).communityKudosControllerCreate(createCommunityKudosDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityKudosApi
     */
    public communityKudosControllerDelete(postId: string, options?: AxiosRequestConfig) {
        return CommunityKudosApiFp(this.configuration).communityKudosControllerDelete(postId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityPostApi - axios parameter creator
 * @export
 */
export const CommunityPostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateCommunityPostDto} createCommunityPostDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerCreate: async (createCommunityPostDto: CreateCommunityPostDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunityPostDto' is not null or undefined
            assertParamExists('communityPostControllerCreate', 'createCommunityPostDto', createCommunityPostDto)
            const localVarPath = `/v1/community-post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunityPostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostControllerDelete', 'id', id)
            const localVarPath = `/v1/community-post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'approved' | 'suspended'>} [communityPostStatus]
         * @param {string} [content]
         * @param {boolean} [attachment]
         * @param {string} [createdAfter]
         * @param {string} [createdBefore]
         * @param {string} [authorId]
         * @param {Array<string>} [categoryIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerGetAll: async (limit: number, offset: number, order?: string, communityPostStatus?: Array<'pending' | 'approved' | 'suspended'>, content?: string, attachment?: boolean, createdAfter?: string, createdBefore?: string, authorId?: string, categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('communityPostControllerGetAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('communityPostControllerGetAll', 'offset', offset)
            const localVarPath = `/v1/community-post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (communityPostStatus) {
                localVarQueryParameter['communityPostStatus'] = communityPostStatus;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (attachment !== undefined) {
                localVarQueryParameter['attachment'] = attachment;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = (createdAfter as any instanceof Date) ?
                    (createdAfter as any).toISOString() :
                    createdAfter;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = (createdBefore as any instanceof Date) ?
                    (createdBefore as any).toISOString() :
                    createdBefore;
            }

            if (authorId !== undefined) {
                localVarQueryParameter['authorId'] = authorId;
            }

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerGetOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostControllerGetOne', 'id', id)
            const localVarPath = `/v1/community-post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerIsPostDeleted: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostControllerIsPostDeleted', 'id', id)
            const localVarPath = `/v1/community-post/{id}/is-deleted`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerTogglePinPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostControllerTogglePinPost', 'id', id)
            const localVarPath = `/v1/community-post/{id}/pin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostDto} updateCommunityPostDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerUpdate: async (id: string, updateCommunityPostDto: UpdateCommunityPostDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostControllerUpdate', 'id', id)
            // verify required parameter 'updateCommunityPostDto' is not null or undefined
            assertParamExists('communityPostControllerUpdate', 'updateCommunityPostDto', updateCommunityPostDto)
            const localVarPath = `/v1/community-post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommunityPostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostStatusDto} updateCommunityPostStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerUpdateStatus: async (id: string, updateCommunityPostStatusDto: UpdateCommunityPostStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostControllerUpdateStatus', 'id', id)
            // verify required parameter 'updateCommunityPostStatusDto' is not null or undefined
            assertParamExists('communityPostControllerUpdateStatus', 'updateCommunityPostStatusDto', updateCommunityPostStatusDto)
            const localVarPath = `/v1/community-post/{id}/update-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommunityPostStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityPostApi - functional programming interface
 * @export
 */
export const CommunityPostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityPostApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateCommunityPostDto} createCommunityPostDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerCreate(createCommunityPostDto: CreateCommunityPostDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPostDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerCreate(createCommunityPostDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'approved' | 'suspended'>} [communityPostStatus]
         * @param {string} [content]
         * @param {boolean} [attachment]
         * @param {string} [createdAfter]
         * @param {string} [createdBefore]
         * @param {string} [authorId]
         * @param {Array<string>} [categoryIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerGetAll(limit: number, offset: number, order?: string, communityPostStatus?: Array<'pending' | 'approved' | 'suspended'>, content?: string, attachment?: boolean, createdAfter?: string, createdBefore?: string, authorId?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPostPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerGetAll(limit, offset, order, communityPostStatus, content, attachment, createdAfter, createdBefore, authorId, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerGetOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPostDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerIsPostDeleted(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IsCommunityPostDeletedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerIsPostDeleted(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerTogglePinPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerTogglePinPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostDto} updateCommunityPostDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerUpdate(id: string, updateCommunityPostDto: UpdateCommunityPostDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPost>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerUpdate(id, updateCommunityPostDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostStatusDto} updateCommunityPostStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostControllerUpdateStatus(id: string, updateCommunityPostStatusDto: UpdateCommunityPostStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostControllerUpdateStatus(id, updateCommunityPostStatusDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityPostApi - factory interface
 * @export
 */
export const CommunityPostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityPostApiFp(configuration)
    return {
        /**
         *
         * @param {CreateCommunityPostDto} createCommunityPostDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerCreate(createCommunityPostDto: CreateCommunityPostDto, options?: any): AxiosPromise<CommunityPostDto> {
            return localVarFp.communityPostControllerCreate(createCommunityPostDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityPostControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'approved' | 'suspended'>} [communityPostStatus]
         * @param {string} [content]
         * @param {boolean} [attachment]
         * @param {string} [createdAfter]
         * @param {string} [createdBefore]
         * @param {string} [authorId]
         * @param {Array<string>} [categoryIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerGetAll(limit: number, offset: number, order?: string, communityPostStatus?: Array<'pending' | 'approved' | 'suspended'>, content?: string, attachment?: boolean, createdAfter?: string, createdBefore?: string, authorId?: string, categoryIds?: Array<string>, options?: any): AxiosPromise<CommunityPostPaginatedResultDto> {
            return localVarFp.communityPostControllerGetAll(limit, offset, order, communityPostStatus, content, attachment, createdAfter, createdBefore, authorId, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerGetOne(id: string, options?: any): AxiosPromise<CommunityPostDto> {
            return localVarFp.communityPostControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerIsPostDeleted(id: string, options?: any): AxiosPromise<IsCommunityPostDeletedDto> {
            return localVarFp.communityPostControllerIsPostDeleted(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerTogglePinPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityPostControllerTogglePinPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostDto} updateCommunityPostDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerUpdate(id: string, updateCommunityPostDto: UpdateCommunityPostDto, options?: any): AxiosPromise<CommunityPost> {
            return localVarFp.communityPostControllerUpdate(id, updateCommunityPostDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostStatusDto} updateCommunityPostStatusDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostControllerUpdateStatus(id: string, updateCommunityPostStatusDto: UpdateCommunityPostStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.communityPostControllerUpdateStatus(id, updateCommunityPostStatusDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityPostApi - object-oriented interface
 * @export
 * @class CommunityPostApi
 * @extends {BaseAPI}
 */
export class CommunityPostApi extends BaseAPI {
    /**
     *
     * @param {CreateCommunityPostDto} createCommunityPostDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerCreate(createCommunityPostDto: CreateCommunityPostDto, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerCreate(createCommunityPostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {Array<'pending' | 'approved' | 'suspended'>} [communityPostStatus]
     * @param {string} [content]
     * @param {boolean} [attachment]
     * @param {string} [createdAfter]
     * @param {string} [createdBefore]
     * @param {string} [authorId]
     * @param {Array<string>} [categoryIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerGetAll(limit: number, offset: number, order?: string, communityPostStatus?: Array<'pending' | 'approved' | 'suspended'>, content?: string, attachment?: boolean, createdAfter?: string, createdBefore?: string, authorId?: string, categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerGetAll(limit, offset, order, communityPostStatus, content, attachment, createdAfter, createdBefore, authorId, categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerGetOne(id: string, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerIsPostDeleted(id: string, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerIsPostDeleted(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerTogglePinPost(id: string, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerTogglePinPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {UpdateCommunityPostDto} updateCommunityPostDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerUpdate(id: string, updateCommunityPostDto: UpdateCommunityPostDto, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerUpdate(id, updateCommunityPostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {UpdateCommunityPostStatusDto} updateCommunityPostStatusDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostApi
     */
    public communityPostControllerUpdateStatus(id: string, updateCommunityPostStatusDto: UpdateCommunityPostStatusDto, options?: AxiosRequestConfig) {
        return CommunityPostApiFp(this.configuration).communityPostControllerUpdateStatus(id, updateCommunityPostStatusDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityPostCommentsApi - axios parameter creator
 * @export
 */
export const CommunityPostCommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateCommunityPostCommentsDto} createCommunityPostCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerCreate: async (createCommunityPostCommentsDto: CreateCommunityPostCommentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunityPostCommentsDto' is not null or undefined
            assertParamExists('communityPostCommentsControllerCreate', 'createCommunityPostCommentsDto', createCommunityPostCommentsDto)
            const localVarPath = `/v1/community-post-comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunityPostCommentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerFindAllByPostId: async (id: string, limit: number, offset: number, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostCommentsControllerFindAllByPostId', 'id', id)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('communityPostCommentsControllerFindAllByPostId', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('communityPostCommentsControllerFindAllByPostId', 'offset', offset)
            const localVarPath = `/v1/community-post-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostCommentsControllerRemove', 'id', id)
            const localVarPath = `/v1/community-post-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostCommentsDto} updateCommunityPostCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerUpdate: async (id: string, updateCommunityPostCommentsDto: UpdateCommunityPostCommentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPostCommentsControllerUpdate', 'id', id)
            // verify required parameter 'updateCommunityPostCommentsDto' is not null or undefined
            assertParamExists('communityPostCommentsControllerUpdate', 'updateCommunityPostCommentsDto', updateCommunityPostCommentsDto)
            const localVarPath = `/v1/community-post-comments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommunityPostCommentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityPostCommentsApi - functional programming interface
 * @export
 */
export const CommunityPostCommentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityPostCommentsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateCommunityPostCommentsDto} createCommunityPostCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostCommentsControllerCreate(createCommunityPostCommentsDto: CreateCommunityPostCommentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPostCommentsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostCommentsControllerCreate(createCommunityPostCommentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostCommentsControllerFindAllByPostId(id: string, limit: number, offset: number, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPostCommentsPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostCommentsControllerFindAllByPostId(id, limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostCommentsControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostCommentsControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostCommentsDto} updateCommunityPostCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPostCommentsControllerUpdate(id: string, updateCommunityPostCommentsDto: UpdateCommunityPostCommentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPostCommentsControllerUpdate(id, updateCommunityPostCommentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityPostCommentsApi - factory interface
 * @export
 */
export const CommunityPostCommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityPostCommentsApiFp(configuration)
    return {
        /**
         *
         * @param {CreateCommunityPostCommentsDto} createCommunityPostCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerCreate(createCommunityPostCommentsDto: CreateCommunityPostCommentsDto, options?: any): AxiosPromise<CommunityPostCommentsDto> {
            return localVarFp.communityPostCommentsControllerCreate(createCommunityPostCommentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerFindAllByPostId(id: string, limit: number, offset: number, order?: string, options?: any): AxiosPromise<CommunityPostCommentsPaginatedResultDto> {
            return localVarFp.communityPostCommentsControllerFindAllByPostId(id, limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityPostCommentsControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UpdateCommunityPostCommentsDto} updateCommunityPostCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPostCommentsControllerUpdate(id: string, updateCommunityPostCommentsDto: UpdateCommunityPostCommentsDto, options?: any): AxiosPromise<void> {
            return localVarFp.communityPostCommentsControllerUpdate(id, updateCommunityPostCommentsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityPostCommentsApi - object-oriented interface
 * @export
 * @class CommunityPostCommentsApi
 * @extends {BaseAPI}
 */
export class CommunityPostCommentsApi extends BaseAPI {
    /**
     *
     * @param {CreateCommunityPostCommentsDto} createCommunityPostCommentsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostCommentsApi
     */
    public communityPostCommentsControllerCreate(createCommunityPostCommentsDto: CreateCommunityPostCommentsDto, options?: AxiosRequestConfig) {
        return CommunityPostCommentsApiFp(this.configuration).communityPostCommentsControllerCreate(createCommunityPostCommentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostCommentsApi
     */
    public communityPostCommentsControllerFindAllByPostId(id: string, limit: number, offset: number, order?: string, options?: AxiosRequestConfig) {
        return CommunityPostCommentsApiFp(this.configuration).communityPostCommentsControllerFindAllByPostId(id, limit, offset, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostCommentsApi
     */
    public communityPostCommentsControllerRemove(id: string, options?: AxiosRequestConfig) {
        return CommunityPostCommentsApiFp(this.configuration).communityPostCommentsControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {UpdateCommunityPostCommentsDto} updateCommunityPostCommentsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPostCommentsApi
     */
    public communityPostCommentsControllerUpdate(id: string, updateCommunityPostCommentsDto: UpdateCommunityPostCommentsDto, options?: AxiosRequestConfig) {
        return CommunityPostCommentsApiFp(this.configuration).communityPostCommentsControllerUpdate(id, updateCommunityPostCommentsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityPromptApi - axios parameter creator
 * @export
 */
export const CommunityPromptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateCommunityPromptDto} createCommunityPromptDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerCreate: async (createCommunityPromptDto: CreateCommunityPromptDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunityPromptDto' is not null or undefined
            assertParamExists('communityPromptControllerCreate', 'createCommunityPromptDto', createCommunityPromptDto)
            const localVarPath = `/v1/community-prompt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunityPromptDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPromptControllerDelete', 'id', id)
            const localVarPath = `/v1/community-prompt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/community-prompt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerGetOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPromptControllerGetOne', 'id', id)
            const localVarPath = `/v1/community-prompt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerGetShuffledPrompt: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPromptControllerGetShuffledPrompt', 'id', id)
            const localVarPath = `/v1/community-prompt/shuffled/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityPromptApi - functional programming interface
 * @export
 */
export const CommunityPromptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityPromptApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateCommunityPromptDto} createCommunityPromptDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPromptControllerCreate(createCommunityPromptDto: CreateCommunityPromptDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPromptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPromptControllerCreate(createCommunityPromptDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPromptControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPromptControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPromptControllerGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunityPromptDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPromptControllerGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPromptControllerGetOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPromptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPromptControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPromptControllerGetShuffledPrompt(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPromptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPromptControllerGetShuffledPrompt(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityPromptApi - factory interface
 * @export
 */
export const CommunityPromptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityPromptApiFp(configuration)
    return {
        /**
         *
         * @param {CreateCommunityPromptDto} createCommunityPromptDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerCreate(createCommunityPromptDto: CreateCommunityPromptDto, options?: any): AxiosPromise<CommunityPromptDto> {
            return localVarFp.communityPromptControllerCreate(createCommunityPromptDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityPromptControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerGetAll(options?: any): AxiosPromise<Array<CommunityPromptDto>> {
            return localVarFp.communityPromptControllerGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerGetOne(id: string, options?: any): AxiosPromise<CommunityPromptDto> {
            return localVarFp.communityPromptControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPromptControllerGetShuffledPrompt(id: string, options?: any): AxiosPromise<CommunityPromptDto> {
            return localVarFp.communityPromptControllerGetShuffledPrompt(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityPromptApi - object-oriented interface
 * @export
 * @class CommunityPromptApi
 * @extends {BaseAPI}
 */
export class CommunityPromptApi extends BaseAPI {
    /**
     *
     * @param {CreateCommunityPromptDto} createCommunityPromptDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPromptApi
     */
    public communityPromptControllerCreate(createCommunityPromptDto: CreateCommunityPromptDto, options?: AxiosRequestConfig) {
        return CommunityPromptApiFp(this.configuration).communityPromptControllerCreate(createCommunityPromptDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPromptApi
     */
    public communityPromptControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CommunityPromptApiFp(this.configuration).communityPromptControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPromptApi
     */
    public communityPromptControllerGetAll(options?: AxiosRequestConfig) {
        return CommunityPromptApiFp(this.configuration).communityPromptControllerGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPromptApi
     */
    public communityPromptControllerGetOne(id: string, options?: AxiosRequestConfig) {
        return CommunityPromptApiFp(this.configuration).communityPromptControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityPromptApi
     */
    public communityPromptControllerGetShuffledPrompt(id: string, options?: AxiosRequestConfig) {
        return CommunityPromptApiFp(this.configuration).communityPromptControllerGetShuffledPrompt(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityUserBanApi - axios parameter creator
 * @export
 */
export const CommunityUserBanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateCommunityUserBanDto} createCommunityUserBanDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerCreate: async (createCommunityUserBanDto: CreateCommunityUserBanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommunityUserBanDto' is not null or undefined
            assertParamExists('communityUserBanControllerCreate', 'createCommunityUserBanDto', createCommunityUserBanDto)
            const localVarPath = `/v1/community-user-ban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommunityUserBanDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityUserBanControllerDelete', 'id', id)
            const localVarPath = `/v1/community-user-ban/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [reason]
         * @param {string} [userBanned]
         * @param {string} [bannedBy]
         * @param {string} [createdBefore]
         * @param {string} [createdAfter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerGetAll: async (limit: number, offset: number, order?: string, reason?: string, userBanned?: string, bannedBy?: string, createdBefore?: string, createdAfter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('communityUserBanControllerGetAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('communityUserBanControllerGetAll', 'offset', offset)
            const localVarPath = `/v1/community-user-ban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (reason !== undefined) {
                localVarQueryParameter['reason'] = reason;
            }

            if (userBanned !== undefined) {
                localVarQueryParameter['userBanned'] = userBanned;
            }

            if (bannedBy !== undefined) {
                localVarQueryParameter['bannedBy'] = bannedBy;
            }

            if (createdBefore !== undefined) {
                localVarQueryParameter['createdBefore'] = (createdBefore as any instanceof Date) ?
                    (createdBefore as any).toISOString() :
                    createdBefore;
            }

            if (createdAfter !== undefined) {
                localVarQueryParameter['createdAfter'] = (createdAfter as any instanceof Date) ?
                    (createdAfter as any).toISOString() :
                    createdAfter;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} userBannedId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerGetOneByUserBannedId: async (userBannedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userBannedId' is not null or undefined
            assertParamExists('communityUserBanControllerGetOneByUserBannedId', 'userBannedId', userBannedId)
            const localVarPath = `/v1/community-user-ban/{userBannedId}`
                .replace(`{${"userBannedId"}}`, encodeURIComponent(String(userBannedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityUserBanApi - functional programming interface
 * @export
 */
export const CommunityUserBanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityUserBanApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateCommunityUserBanDto} createCommunityUserBanDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityUserBanControllerCreate(createCommunityUserBanDto: CreateCommunityUserBanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityUserBanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityUserBanControllerCreate(createCommunityUserBanDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityUserBanControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityUserBanControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [reason]
         * @param {string} [userBanned]
         * @param {string} [bannedBy]
         * @param {string} [createdBefore]
         * @param {string} [createdAfter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityUserBanControllerGetAll(limit: number, offset: number, order?: string, reason?: string, userBanned?: string, bannedBy?: string, createdBefore?: string, createdAfter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityUserBanPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityUserBanControllerGetAll(limit, offset, order, reason, userBanned, bannedBy, createdBefore, createdAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} userBannedId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityUserBanControllerGetOneByUserBannedId(userBannedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityUserBanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityUserBanControllerGetOneByUserBannedId(userBannedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityUserBanApi - factory interface
 * @export
 */
export const CommunityUserBanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityUserBanApiFp(configuration)
    return {
        /**
         *
         * @param {CreateCommunityUserBanDto} createCommunityUserBanDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerCreate(createCommunityUserBanDto: CreateCommunityUserBanDto, options?: any): AxiosPromise<CommunityUserBanDto> {
            return localVarFp.communityUserBanControllerCreate(createCommunityUserBanDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.communityUserBanControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [reason]
         * @param {string} [userBanned]
         * @param {string} [bannedBy]
         * @param {string} [createdBefore]
         * @param {string} [createdAfter]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerGetAll(limit: number, offset: number, order?: string, reason?: string, userBanned?: string, bannedBy?: string, createdBefore?: string, createdAfter?: string, options?: any): AxiosPromise<CommunityUserBanPaginatedResultDto> {
            return localVarFp.communityUserBanControllerGetAll(limit, offset, order, reason, userBanned, bannedBy, createdBefore, createdAfter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userBannedId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityUserBanControllerGetOneByUserBannedId(userBannedId: string, options?: any): AxiosPromise<CommunityUserBanDto> {
            return localVarFp.communityUserBanControllerGetOneByUserBannedId(userBannedId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityUserBanApi - object-oriented interface
 * @export
 * @class CommunityUserBanApi
 * @extends {BaseAPI}
 */
export class CommunityUserBanApi extends BaseAPI {
    /**
     *
     * @param {CreateCommunityUserBanDto} createCommunityUserBanDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityUserBanApi
     */
    public communityUserBanControllerCreate(createCommunityUserBanDto: CreateCommunityUserBanDto, options?: AxiosRequestConfig) {
        return CommunityUserBanApiFp(this.configuration).communityUserBanControllerCreate(createCommunityUserBanDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityUserBanApi
     */
    public communityUserBanControllerDelete(id: string, options?: AxiosRequestConfig) {
        return CommunityUserBanApiFp(this.configuration).communityUserBanControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [reason]
     * @param {string} [userBanned]
     * @param {string} [bannedBy]
     * @param {string} [createdBefore]
     * @param {string} [createdAfter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityUserBanApi
     */
    public communityUserBanControllerGetAll(limit: number, offset: number, order?: string, reason?: string, userBanned?: string, bannedBy?: string, createdBefore?: string, createdAfter?: string, options?: AxiosRequestConfig) {
        return CommunityUserBanApiFp(this.configuration).communityUserBanControllerGetAll(limit, offset, order, reason, userBanned, bannedBy, createdBefore, createdAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} userBannedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityUserBanApi
     */
    public communityUserBanControllerGetOneByUserBannedId(userBannedId: string, options?: AxiosRequestConfig) {
        return CommunityUserBanApiFp(this.configuration).communityUserBanControllerGetOneByUserBannedId(userBannedId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketplaceAttachmentApi - axios parameter creator
 * @export
 */
export const MarketplaceAttachmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAttachmentControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketplaceAttachmentControllerFindOne', 'id', id)
            const localVarPath = `/v1/marketplace-attachment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAttachmentControllerUploadAttachment: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/marketplace-attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }


            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceAttachmentApi - functional programming interface
 * @export
 */
export const MarketplaceAttachmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceAttachmentApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceAttachmentControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceAttachmentControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceAttachmentControllerUploadAttachment(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceAttachmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceAttachmentControllerUploadAttachment(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceAttachmentApi - factory interface
 * @export
 */
export const MarketplaceAttachmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceAttachmentApiFp(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAttachmentControllerFindOne(id: string, options?: any): AxiosPromise<MarketplaceAttachmentDto> {
            return localVarFp.marketplaceAttachmentControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceAttachmentControllerUploadAttachment(file?: any, options?: any): AxiosPromise<MarketplaceAttachmentDto> {
            return localVarFp.marketplaceAttachmentControllerUploadAttachment(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketplaceAttachmentApi - object-oriented interface
 * @export
 * @class MarketplaceAttachmentApi
 * @extends {BaseAPI}
 */
export class MarketplaceAttachmentApi extends BaseAPI {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceAttachmentApi
     */
    public marketplaceAttachmentControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return MarketplaceAttachmentApiFp(this.configuration).marketplaceAttachmentControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceAttachmentApi
     */
    public marketplaceAttachmentControllerUploadAttachment(file?: any, options?: AxiosRequestConfig) {
        return MarketplaceAttachmentApiFp(this.configuration).marketplaceAttachmentControllerUploadAttachment(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketplaceItemApi - axios parameter creator
 * @export
 */
export const MarketplaceItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {CreateMarketplaceItemDto} createMarketplaceItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerCreate: async (createMarketplaceItemDto: CreateMarketplaceItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMarketplaceItemDto' is not null or undefined
            assertParamExists('marketplaceItemControllerCreate', 'createMarketplaceItemDto', createMarketplaceItemDto)
            const localVarPath = `/v1/marketplace-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMarketplaceItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketplaceItemControllerDelete', 'id', id)
            const localVarPath = `/v1/marketplace-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [brand]
         * @param {string} [name]
         * @param {string} [description]
         * @param {number} [price]
         * @param {string} [websiteLink]
         * @param {Array<'product' | 'service'>} [itemTypes]
         * @param {Array<string>} [categoryIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerGetAll: async (limit: number, offset: number, order?: string, brand?: string, name?: string, description?: string, price?: number, websiteLink?: string, itemTypes?: Array<'product' | 'service'>, categoryIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('marketplaceItemControllerGetAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('marketplaceItemControllerGetAll', 'offset', offset)
            const localVarPath = `/v1/marketplace-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (price !== undefined) {
                localVarQueryParameter['price'] = price;
            }

            if (websiteLink !== undefined) {
                localVarQueryParameter['websiteLink'] = websiteLink;
            }

            if (itemTypes) {
                localVarQueryParameter['itemTypes'] = itemTypes;
            }

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerGetOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketplaceItemControllerGetOne', 'id', id)
            const localVarPath = `/v1/marketplace-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {UpdateMarketplaceItemDto} updateMarketplaceItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerUpdate: async (id: string, updateMarketplaceItemDto: UpdateMarketplaceItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('marketplaceItemControllerUpdate', 'id', id)
            // verify required parameter 'updateMarketplaceItemDto' is not null or undefined
            assertParamExists('marketplaceItemControllerUpdate', 'updateMarketplaceItemDto', updateMarketplaceItemDto)
            const localVarPath = `/v1/marketplace-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMarketplaceItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceItemApi - functional programming interface
 * @export
 */
export const MarketplaceItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceItemApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {CreateMarketplaceItemDto} createMarketplaceItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceItemControllerCreate(createMarketplaceItemDto: CreateMarketplaceItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceItemControllerCreate(createMarketplaceItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceItemControllerDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceItemControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [brand]
         * @param {string} [name]
         * @param {string} [description]
         * @param {number} [price]
         * @param {string} [websiteLink]
         * @param {Array<'product' | 'service'>} [itemTypes]
         * @param {Array<string>} [categoryIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceItemControllerGetAll(limit: number, offset: number, order?: string, brand?: string, name?: string, description?: string, price?: number, websiteLink?: string, itemTypes?: Array<'product' | 'service'>, categoryIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceItemPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceItemControllerGetAll(limit, offset, order, brand, name, description, price, websiteLink, itemTypes, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceItemControllerGetOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceItemControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {UpdateMarketplaceItemDto} updateMarketplaceItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async marketplaceItemControllerUpdate(id: string, updateMarketplaceItemDto: UpdateMarketplaceItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketplaceItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.marketplaceItemControllerUpdate(id, updateMarketplaceItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceItemApi - factory interface
 * @export
 */
export const MarketplaceItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceItemApiFp(configuration)
    return {
        /**
         *
         * @param {CreateMarketplaceItemDto} createMarketplaceItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerCreate(createMarketplaceItemDto: CreateMarketplaceItemDto, options?: any): AxiosPromise<MarketplaceItemDto> {
            return localVarFp.marketplaceItemControllerCreate(createMarketplaceItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.marketplaceItemControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [brand]
         * @param {string} [name]
         * @param {string} [description]
         * @param {number} [price]
         * @param {string} [websiteLink]
         * @param {Array<'product' | 'service'>} [itemTypes]
         * @param {Array<string>} [categoryIds]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerGetAll(limit: number, offset: number, order?: string, brand?: string, name?: string, description?: string, price?: number, websiteLink?: string, itemTypes?: Array<'product' | 'service'>, categoryIds?: Array<string>, options?: any): AxiosPromise<MarketplaceItemPaginatedResultDto> {
            return localVarFp.marketplaceItemControllerGetAll(limit, offset, order, brand, name, description, price, websiteLink, itemTypes, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerGetOne(id: string, options?: any): AxiosPromise<MarketplaceItemDto> {
            return localVarFp.marketplaceItemControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {UpdateMarketplaceItemDto} updateMarketplaceItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marketplaceItemControllerUpdate(id: string, updateMarketplaceItemDto: UpdateMarketplaceItemDto, options?: any): AxiosPromise<MarketplaceItemDto> {
            return localVarFp.marketplaceItemControllerUpdate(id, updateMarketplaceItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketplaceItemApi - object-oriented interface
 * @export
 * @class MarketplaceItemApi
 * @extends {BaseAPI}
 */
export class MarketplaceItemApi extends BaseAPI {
    /**
     *
     * @param {CreateMarketplaceItemDto} createMarketplaceItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceItemApi
     */
    public marketplaceItemControllerCreate(createMarketplaceItemDto: CreateMarketplaceItemDto, options?: AxiosRequestConfig) {
        return MarketplaceItemApiFp(this.configuration).marketplaceItemControllerCreate(createMarketplaceItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceItemApi
     */
    public marketplaceItemControllerDelete(id: string, options?: AxiosRequestConfig) {
        return MarketplaceItemApiFp(this.configuration).marketplaceItemControllerDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [brand]
     * @param {string} [name]
     * @param {string} [description]
     * @param {number} [price]
     * @param {string} [websiteLink]
     * @param {Array<'product' | 'service'>} [itemTypes]
     * @param {Array<string>} [categoryIds]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceItemApi
     */
    public marketplaceItemControllerGetAll(limit: number, offset: number, order?: string, brand?: string, name?: string, description?: string, price?: number, websiteLink?: string, itemTypes?: Array<'product' | 'service'>, categoryIds?: Array<string>, options?: AxiosRequestConfig) {
        return MarketplaceItemApiFp(this.configuration).marketplaceItemControllerGetAll(limit, offset, order, brand, name, description, price, websiteLink, itemTypes, categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceItemApi
     */
    public marketplaceItemControllerGetOne(id: string, options?: AxiosRequestConfig) {
        return MarketplaceItemApiFp(this.configuration).marketplaceItemControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {UpdateMarketplaceItemDto} updateMarketplaceItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceItemApi
     */
    public marketplaceItemControllerUpdate(id: string, updateMarketplaceItemDto: UpdateMarketplaceItemDto, options?: AxiosRequestConfig) {
        return MarketplaceItemApiFp(this.configuration).marketplaceItemControllerUpdate(id, updateMarketplaceItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicCommunityPostApi - axios parameter creator
 * @export
 */
export const PublicCommunityPostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPublicPostControllerGetOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('communityPublicPostControllerGetOne', 'id', id)
            const localVarPath = `/v1/public/community-post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicCommunityPostApi - functional programming interface
 * @export
 */
export const PublicCommunityPostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicCommunityPostApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityPublicPostControllerGetOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityPublicPostDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityPublicPostControllerGetOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicCommunityPostApi - factory interface
 * @export
 */
export const PublicCommunityPostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicCommunityPostApiFp(configuration)
    return {
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityPublicPostControllerGetOne(id: string, options?: any): AxiosPromise<CommunityPublicPostDto> {
            return localVarFp.communityPublicPostControllerGetOne(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicCommunityPostApi - object-oriented interface
 * @export
 * @class PublicCommunityPostApi
 * @extends {BaseAPI}
 */
export class PublicCommunityPostApi extends BaseAPI {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicCommunityPostApi
     */
    public communityPublicPostControllerGetOne(id: string, options?: AxiosRequestConfig) {
        return PublicCommunityPostApiFp(this.configuration).communityPublicPostControllerGetOne(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QueueApi - axios parameter creator
 * @export
 */
export const QueueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateForAccount: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('queueControllerCreateForAccount', 'accountId', accountId)
            const localVarPath = `/v1/queue/general/queue-entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {QueueEntryCreateDto} queueEntryCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateQueueEntry: async (queueEntryCreateDto: QueueEntryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueEntryCreateDto' is not null or undefined
            assertParamExists('queueControllerCreateQueueEntry', 'queueEntryCreateDto', queueEntryCreateDto)
            const localVarPath = `/v1/queue/scheduled/queue-entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queueEntryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueControllerRemove', 'id', id)
            const localVarPath = `/v1/queue/scheduled/queue-entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {QueueEntryCreateDto} queueEntryCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerUpdate: async (id: string, queueEntryCreateDto: QueueEntryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueControllerUpdate', 'id', id)
            // verify required parameter 'queueEntryCreateDto' is not null or undefined
            assertParamExists('queueControllerUpdate', 'queueEntryCreateDto', queueEntryCreateDto)
            const localVarPath = `/v1/queue/scheduled/queue-entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queueEntryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerAssign: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerAssign', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerComplete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerComplete', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
         * @param {string} [updatedAfter]
         * @param {string} [updatedBefore]
         * @param {string} [scheduledForStartAfter]
         * @param {string} [scheduledForStartBefore]
         * @param {'general' | 'scheduled'} [queueType]
         * @param {string} [coachId]
         * @param {string} [physicianId]
         * @param {string} [lastMessageTimestamp]
         * @param {string} [accountId]
         * @param {boolean} [hasNoCoach]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAll: async (limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryControllerFindAll', 'offset', offset)
            const localVarPath = `/v1/queue-entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updatedBefore'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }

            if (scheduledForStartAfter !== undefined) {
                localVarQueryParameter['scheduledForStartAfter'] = (scheduledForStartAfter as any instanceof Date) ?
                    (scheduledForStartAfter as any).toISOString() :
                    scheduledForStartAfter;
            }

            if (scheduledForStartBefore !== undefined) {
                localVarQueryParameter['scheduledForStartBefore'] = (scheduledForStartBefore as any instanceof Date) ?
                    (scheduledForStartBefore as any).toISOString() :
                    scheduledForStartBefore;
            }

            if (queueType !== undefined) {
                localVarQueryParameter['queueType'] = queueType;
            }

            if (coachId !== undefined) {
                localVarQueryParameter['coachId'] = coachId;
            }

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }

            if (lastMessageTimestamp !== undefined) {
                localVarQueryParameter['lastMessageTimestamp'] = (lastMessageTimestamp as any instanceof Date) ?
                    (lastMessageTimestamp as any).toISOString() :
                    lastMessageTimestamp;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (hasNoCoach !== undefined) {
                localVarQueryParameter['hasNoCoach'] = hasNoCoach;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
         * @param {string} [updatedAfter]
         * @param {string} [updatedBefore]
         * @param {string} [scheduledForStartAfter]
         * @param {string} [scheduledForStartBefore]
         * @param {'general' | 'scheduled'} [queueType]
         * @param {string} [coachId]
         * @param {string} [physicianId]
         * @param {string} [lastMessageTimestamp]
         * @param {string} [accountId]
         * @param {boolean} [hasNoCoach]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAllScheduled: async (limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryControllerFindAllScheduled', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryControllerFindAllScheduled', 'offset', offset)
            const localVarPath = `/v1/queue-entry/scheduled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updatedBefore'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }

            if (scheduledForStartAfter !== undefined) {
                localVarQueryParameter['scheduledForStartAfter'] = (scheduledForStartAfter as any instanceof Date) ?
                    (scheduledForStartAfter as any).toISOString() :
                    scheduledForStartAfter;
            }

            if (scheduledForStartBefore !== undefined) {
                localVarQueryParameter['scheduledForStartBefore'] = (scheduledForStartBefore as any instanceof Date) ?
                    (scheduledForStartBefore as any).toISOString() :
                    scheduledForStartBefore;
            }

            if (queueType !== undefined) {
                localVarQueryParameter['queueType'] = queueType;
            }

            if (coachId !== undefined) {
                localVarQueryParameter['coachId'] = coachId;
            }

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }

            if (lastMessageTimestamp !== undefined) {
                localVarQueryParameter['lastMessageTimestamp'] = (lastMessageTimestamp as any instanceof Date) ?
                    (lastMessageTimestamp as any).toISOString() :
                    lastMessageTimestamp;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (hasNoCoach !== undefined) {
                localVarQueryParameter['hasNoCoach'] = hasNoCoach;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerFindOne', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindRecent: async (limit: number, offset: number, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryControllerFindRecent', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryControllerFindRecent', 'offset', offset)
            const localVarPath = `/v1/queue-entry/past-scheduled-patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerUnassign: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerUnassign', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}/unassign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueueApi - functional programming interface
 * @export
 */
export const QueueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueueApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerCreateForAccount(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerCreateForAccount(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {QueueEntryCreateDto} queueEntryCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerCreateQueueEntry(queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerCreateQueueEntry(queueEntryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {QueueEntryCreateDto} queueEntryCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerUpdate(id: string, queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerUpdate(id, queueEntryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerAssign(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerAssign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerComplete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerComplete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
         * @param {string} [updatedAfter]
         * @param {string} [updatedBefore]
         * @param {string} [scheduledForStartAfter]
         * @param {string} [scheduledForStartBefore]
         * @param {'general' | 'scheduled'} [queueType]
         * @param {string} [coachId]
         * @param {string} [physicianId]
         * @param {string} [lastMessageTimestamp]
         * @param {string} [accountId]
         * @param {boolean} [hasNoCoach]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindAll(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindAll(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
         * @param {string} [updatedAfter]
         * @param {string} [updatedBefore]
         * @param {string} [scheduledForStartAfter]
         * @param {string} [scheduledForStartBefore]
         * @param {'general' | 'scheduled'} [queueType]
         * @param {string} [coachId]
         * @param {string} [physicianId]
         * @param {string} [lastMessageTimestamp]
         * @param {string} [accountId]
         * @param {boolean} [hasNoCoach]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindAllScheduled(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindAllScheduled(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindRecent(limit: number, offset: number, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindRecent(limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerUnassign(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerUnassign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QueueApi - factory interface
 * @export
 */
export const QueueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueueApiFp(configuration)
    return {
        /**
         *
         * @param {string} accountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateForAccount(accountId: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueControllerCreateForAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {QueueEntryCreateDto} queueEntryCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateQueueEntry(queueEntryCreateDto: QueueEntryCreateDto, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueControllerCreateQueueEntry(queueEntryCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.queueControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.queueControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {QueueEntryCreateDto} queueEntryCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerUpdate(id: string, queueEntryCreateDto: QueueEntryCreateDto, options?: any): AxiosPromise<QueueEntry> {
            return localVarFp.queueControllerUpdate(id, queueEntryCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerAssign(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerAssign(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerComplete(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerComplete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
         * @param {string} [updatedAfter]
         * @param {string} [updatedBefore]
         * @param {string} [scheduledForStartAfter]
         * @param {string} [scheduledForStartBefore]
         * @param {'general' | 'scheduled'} [queueType]
         * @param {string} [coachId]
         * @param {string} [physicianId]
         * @param {string} [lastMessageTimestamp]
         * @param {string} [accountId]
         * @param {boolean} [hasNoCoach]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAll(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryControllerFindAll(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
         * @param {string} [updatedAfter]
         * @param {string} [updatedBefore]
         * @param {string} [scheduledForStartAfter]
         * @param {string} [scheduledForStartBefore]
         * @param {'general' | 'scheduled'} [queueType]
         * @param {string} [coachId]
         * @param {string} [physicianId]
         * @param {string} [lastMessageTimestamp]
         * @param {string} [accountId]
         * @param {boolean} [hasNoCoach]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAllScheduled(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryControllerFindAllScheduled(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindOne(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindRecent(limit: number, offset: number, order?: string, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryControllerFindRecent(limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerUnassign(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerUnassign(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueueApi - object-oriented interface
 * @export
 * @class QueueApi
 * @extends {BaseAPI}
 */
export class QueueApi extends BaseAPI {
    /**
     *
     * @param {string} accountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerCreateForAccount(accountId: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerCreateForAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {QueueEntryCreateDto} queueEntryCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerCreateQueueEntry(queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerCreateQueueEntry(queueEntryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerFindAll(options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerRemove(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {QueueEntryCreateDto} queueEntryCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerUpdate(id: string, queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerUpdate(id, queueEntryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerAssign(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerAssign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerComplete(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerComplete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
     * @param {string} [updatedAfter]
     * @param {string} [updatedBefore]
     * @param {string} [scheduledForStartAfter]
     * @param {string} [scheduledForStartBefore]
     * @param {'general' | 'scheduled'} [queueType]
     * @param {string} [coachId]
     * @param {string} [physicianId]
     * @param {string} [lastMessageTimestamp]
     * @param {string} [accountId]
     * @param {boolean} [hasNoCoach]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindAll(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindAll(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status]
     * @param {string} [updatedAfter]
     * @param {string} [updatedBefore]
     * @param {string} [scheduledForStartAfter]
     * @param {string} [scheduledForStartBefore]
     * @param {'general' | 'scheduled'} [queueType]
     * @param {string} [coachId]
     * @param {string} [physicianId]
     * @param {string} [lastMessageTimestamp]
     * @param {string} [accountId]
     * @param {boolean} [hasNoCoach]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindAllScheduled(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindAllScheduled(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindRecent(limit: number, offset: number, order?: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindRecent(limit, offset, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerUnassign(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerUnassign(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QueueEntryHistoryApi - axios parameter creator
 * @export
 */
export const QueueEntryHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryHistoryControllerFindAll: async (limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryHistoryControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryHistoryControllerFindAll', 'offset', offset)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('queueEntryHistoryControllerFindAll', 'status', status)
            const localVarPath = `/v1/queue-entry-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueueEntryHistoryApi - functional programming interface
 * @export
 */
export const QueueEntryHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueueEntryHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryHistoryControllerFindAll(limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryHistoryControllerFindAll(limit, offset, status, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QueueEntryHistoryApi - factory interface
 * @export
 */
export const QueueEntryHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueueEntryHistoryApiFp(configuration)
    return {
        /**
         *
         * @param {number} limit
         * @param {number} offset
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryHistoryControllerFindAll(limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryHistoryControllerFindAll(limit, offset, status, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueueEntryHistoryApi - object-oriented interface
 * @export
 * @class QueueEntryHistoryApi
 * @extends {BaseAPI}
 */
export class QueueEntryHistoryApi extends BaseAPI {
    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueEntryHistoryApi
     */
    public queueEntryHistoryControllerFindAll(limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options?: AxiosRequestConfig) {
        return QueueEntryHistoryApiFp(this.configuration).queueEntryHistoryControllerFindAll(limit, offset, status, order, options).then((request) => request(this.axios, this.basePath));
    }
}


