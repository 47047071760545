/* tslint:disable */
/* eslint-disable */
/**
 * Sunrise User API
 * User and account management API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptCommunityRulesDto
 */
export interface AcceptCommunityRulesDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptCommunityRulesDto
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface AcceptTosDto
 */
export interface AcceptTosDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptTosDto
     */
    'tosVersion': string;
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<Membership>}
     * @memberof Account
     */
    'memberships': Array<Membership>;
    /**
     * 
     * @type {Array<Invitation>}
     * @memberof Account
     */
    'invitations': Array<Invitation>;
    /**
     * 
     * @type {object}
     * @memberof Account
     */
    'deletionScheduledFor': object;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    'aggregateMembersText': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Account
     */
    'aggregateMembersTextVector': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof Account
     */
    'programActiveAt'?: object;
    /**
     * 
     * @type {object}
     * @memberof Account
     */
    'programExpireAt'?: object;
}
/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'id': string;
    /**
     * 
     * @type {UserDto}
     * @memberof AccountDto
     */
    'owner': UserDto;
    /**
     * 
     * @type {Array<MembershipDto>}
     * @memberof AccountDto
     */
    'memberships': Array<MembershipDto>;
}
/**
 * 
 * @export
 * @interface AccountFilterUserResponseDto
 */
export interface AccountFilterUserResponseDto {
    /**
     * 
     * @type {Array<AccountResponseDto>}
     * @memberof AccountFilterUserResponseDto
     */
    'results': Array<AccountResponseDto>;
}
/**
 * 
 * @export
 * @interface AccountPaginatedItemDto
 */
export interface AccountPaginatedItemDto {
    /**
     * 
     * @type {string}
     * @memberof AccountPaginatedItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPaginatedItemDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPaginatedItemDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPaginatedItemDto
     */
    'deletionScheduledFor': string | null;
    /**
     * 
     * @type {AccountUserResponseDto}
     * @memberof AccountPaginatedItemDto
     */
    'owner': AccountUserResponseDto;
    /**
     * 
     * @type {Array<MembershipDto>}
     * @memberof AccountPaginatedItemDto
     */
    'memberships': Array<MembershipDto>;
    /**
     * 
     * @type {Array<InvitationResponseDto>}
     * @memberof AccountPaginatedItemDto
     */
    'invitations': Array<InvitationResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPaginatedItemDto
     */
    'isProgramActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPaginatedItemDto
     */
    'isFirstAssessmentCompleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPaginatedItemDto
     */
    'isFirstAssessmentStarted'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountPaginatedResultDto
 */
export interface AccountPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof AccountPaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof AccountPaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof AccountPaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<AccountPaginatedItemDto>}
     * @memberof AccountPaginatedResultDto
     */
    'results': Array<AccountPaginatedItemDto>;
}
/**
 * 
 * @export
 * @interface AccountResponseDto
 */
export interface AccountResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountResponseDto
     */
    'deletionScheduledFor': string | null;
    /**
     * 
     * @type {AccountUserResponseDto}
     * @memberof AccountResponseDto
     */
    'owner': AccountUserResponseDto;
    /**
     * 
     * @type {Array<MembershipResponseDto>}
     * @memberof AccountResponseDto
     */
    'memberships': Array<MembershipResponseDto>;
    /**
     * 
     * @type {Array<InvitationResponseDto>}
     * @memberof AccountResponseDto
     */
    'invitations': Array<InvitationResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResponseDto
     */
    'isProgramActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResponseDto
     */
    'isFirstAssessmentCompleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountResponseDto
     */
    'isFirstAssessmentStarted'?: boolean;
}
/**
 * 
 * @export
 * @interface AccountUserResponseDto
 */
export interface AccountUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountUserResponseDto
     */
    'isCaregiver': boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'userType': AccountUserResponseDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'deletedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'tosAcceptedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'tosVersion': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'birthday': string;
    /**
     * 
     * @type {number}
     * @memberof AccountUserResponseDto
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountUserResponseDto
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'communityName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'communityRole': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'communityBio': string;
    /**
     * 
     * @type {string}
     * @memberof AccountUserResponseDto
     */
    'communityWebsiteLink': string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof AccountUserResponseDto
     */
    'communityMemberType': CommunityMemberType;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountUserResponseDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 * 
 * @export
 * @interface AppVersionResponseDto
 */
export interface AppVersionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponseDto
     */
    'latestBuildVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppVersionResponseDto
     */
    'minimumSupportedBuildVersion'?: string;
}
/**
 * 
 * @export
 * @interface AuthenticationResponseDto
 */
export interface AuthenticationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'accessToken': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponseDto
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface CheckAccountPhoneResponseDto
 */
export interface CheckAccountPhoneResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof CheckAccountPhoneResponseDto
     */
    'phoneNumberHasAccount': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum CommunityMemberType {
    Member = 'member',
    HelloFamTeam = 'hello-fam-team',
    KeyOpinionLeader = 'key-opinion-leader'
}

/**
 * 
 * @export
 * @interface CommunityRulesAcceptance
 */
export interface CommunityRulesAcceptance {
    /**
     * 
     * @type {string}
     * @memberof CommunityRulesAcceptance
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommunityRulesAcceptance
     */
    'acceptedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CommunityRulesAcceptance
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface CreateFavoriteAccountDto
 */
export interface CreateFavoriteAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFavoriteAccountDto
     */
    'accountId': string;
}
/**
 * 
 * @export
 * @interface CreateGroupCareReceiverDto
 */
export interface CreateGroupCareReceiverDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupCareReceiverDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupCareReceiverDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupCareReceiverDto
     */
    'phoneCountryCode': CreateGroupCareReceiverDtoPhoneCountryCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupCareReceiverDto
     */
    'mobileNumber': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateGroupCareReceiverDtoPhoneCountryCodeEnum {
    _1 = '+1',
    _55 = '+55'
}

/**
 * 
 * @export
 * @interface CreateGroupDto
 */
export interface CreateGroupDto {
    /**
     * 
     * @type {CreateGroupCareReceiverDto}
     * @memberof CreateGroupDto
     */
    'careReceiver': CreateGroupCareReceiverDto;
    /**
     * 
     * @type {Array<CreateGroupMemberDto>}
     * @memberof CreateGroupDto
     */
    'members': Array<CreateGroupMemberDto>;
}
/**
 * 
 * @export
 * @interface CreateGroupMemberDto
 */
export interface CreateGroupMemberDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupMemberDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupMemberDto
     */
    'phoneCountryCode': CreateGroupMemberDtoPhoneCountryCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateGroupMemberDto
     */
    'invitedMobileNumber': string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateGroupMemberDtoPhoneCountryCodeEnum {
    _1 = '+1',
    _55 = '+55'
}

/**
 * 
 * @export
 * @interface CreateInvitationDto
 */
export interface CreateInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDto
     */
    'phoneCountryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDto
     */
    'invitedMobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvitationDto
     */
    'isCareReceiver': boolean;
}
/**
 * 
 * @export
 * @interface CreateInvitationGroupDto
 */
export interface CreateInvitationGroupDto {
    /**
     * 
     * @type {Array<CreateInvitationDto>}
     * @memberof CreateInvitationGroupDto
     */
    'members': Array<CreateInvitationDto>;
}
/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'userType': CreateUserDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'communityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'communityRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'communityBio'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    'communityWebsiteLink'?: string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof CreateUserDto
     */
    'communityMemberType'?: CommunityMemberType;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 * 
 * @export
 * @interface EmailChangePasswordDto
 */
export interface EmailChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof EmailChangePasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface FavoriteAccountResponseDto
 */
export interface FavoriteAccountResponseDto {
    /**
     * 
     * @type {string}
     * @memberof FavoriteAccountResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAccountResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteAccountResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {AccountDto}
     * @memberof FavoriteAccountResponseDto
     */
    'account': AccountDto;
    /**
     * 
     * @type {UserDto}
     * @memberof FavoriteAccountResponseDto
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface Invitation
 */
export interface Invitation {
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'resolvedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'invitedMobileNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof Invitation
     */
    'isAcceptanceNotifiedToHost': boolean;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'status': InvitationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Invitation
     */
    'lastSeenByOwnerAccountAt'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvitationStatusEnum {
    InvitationPending = 'invitation_pending',
    InvitationRejected = 'invitation_rejected',
    ApprovalPending = 'approval_pending',
    ApprovalRejected = 'approval_rejected',
    Accepted = 'accepted'
}

/**
 * 
 * @export
 * @interface InvitationOwnerDto
 */
export interface InvitationOwnerDto {
    /**
     * 
     * @type {string}
     * @memberof InvitationOwnerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationOwnerDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationOwnerDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationOwnerDto
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationOwnerDto
     */
    'phone': string;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationOwnerDto
     */
    'isCaregiver': boolean;
}
/**
 * 
 * @export
 * @interface InvitationResponseDto
 */
export interface InvitationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'resolvedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'invitedMobileNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof InvitationResponseDto
     */
    'isAcceptanceNotifiedToHost': boolean;
    /**
     * 
     * @type {InvitationOwnerDto}
     * @memberof InvitationResponseDto
     */
    'owner': InvitationOwnerDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'status': InvitationResponseDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationResponseDto
     */
    'lastSeenByOwnerAccountAt'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InvitationResponseDtoStatusEnum {
    InvitationPending = 'invitation_pending',
    InvitationRejected = 'invitation_rejected',
    ApprovalPending = 'approval_pending',
    ApprovalRejected = 'approval_rejected',
    Accepted = 'accepted'
}

/**
 * 
 * @export
 * @interface Membership
 */
export interface Membership {
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'relationship': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'grantedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Membership
     */
    'endedAt': string;
}
/**
 * 
 * @export
 * @interface MembershipDto
 */
export interface MembershipDto {
    /**
     * 
     * @type {string}
     * @memberof MembershipDto
     */
    'id': string;
    /**
     * 
     * @type {UserDto}
     * @memberof MembershipDto
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface MembershipResponseDto
 */
export interface MembershipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MembershipResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponseDto
     */
    'relationship': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponseDto
     */
    'grantedAt': string;
    /**
     * 
     * @type {string}
     * @memberof MembershipResponseDto
     */
    'endedAt': string;
    /**
     * 
     * @type {AccountUserResponseDto}
     * @memberof MembershipResponseDto
     */
    'user': AccountUserResponseDto;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'newPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'verificationToken': string;
}
/**
 * 
 * @export
 * @interface SignInWithEmailDto
 */
export interface SignInWithEmailDto {
    /**
     * 
     * @type {string}
     * @memberof SignInWithEmailDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignInWithEmailDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignInWithEmailResponseDto
 */
export interface SignInWithEmailResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SignInWithEmailResponseDto
     */
    'verificationId': string;
}
/**
 * 
 * @export
 * @interface SignInWithPhoneDto
 */
export interface SignInWithPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof SignInWithPhoneDto
     */
    'phoneCountryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignInWithPhoneDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface SignInWithPhoneResponseDto
 */
export interface SignInWithPhoneResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SignInWithPhoneResponseDto
     */
    'verificationId': string;
}
/**
 * 
 * @export
 * @interface UpdateInvitationDto
 */
export interface UpdateInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateInvitationDto
     */
    'lastSeenByOwnerAccountAt': string;
}
/**
 * 
 * @export
 * @interface UpdateMeDto
 */
export interface UpdateMeDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'lastName': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMeDto
     */
    'isCaregiver': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMeDto
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateMeDto
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'communityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'communityRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'communityBio'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMeDto
     */
    'communityWebsiteLink'?: string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof UpdateMeDto
     */
    'communityMemberType'?: CommunityMemberType;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'birthday'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'userType'?: UpdateUserDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'communityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'communityRole'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'communityBio'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'communityWebsiteLink'?: string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof UpdateUserDto
     */
    'communityMemberType'?: CommunityMemberType;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateUserDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userType': UserUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tosAcceptedAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'birthday': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'tosVersion': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isCaregiver': boolean;
    /**
     * 
     * @type {Account}
     * @memberof User
     */
    'account': Account;
    /**
     * 
     * @type {Array<Membership>}
     * @memberof User
     */
    'memberships': Array<Membership>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'deletionScheduledFor': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'onboardingCompletedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profilePictureUploadedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'removedProfilePictureAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isTestUser': boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'weight': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'height': number;
    /**
     * 
     * @type {Array<CommunityRulesAcceptance>}
     * @memberof User
     */
    'communityRulesAcceptances': Array<CommunityRulesAcceptance>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'communityName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'communityRole': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'communityBio': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'communityWebsiteLink': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'communityMemberType': UserCommunityMemberTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach',
    Assistant = 'assistant'
}
/**
    * @export
    * @enum {string}
    */
export enum UserCommunityMemberTypeEnum {
    Member = 'member',
    HelloFamTeam = 'hello-fam-team',
    KeyOpinionLeader = 'key-opinion-leader'
}

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'birthday': string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityRole': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityBio': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'communityWebsiteLink': string;
    /**
     * 
     * @type {CommunityMemberType}
     * @memberof UserDto
     */
    'communityMemberType': CommunityMemberType;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'profilePictureUrl'?: string;
}
/**
 * 
 * @export
 * @interface UserPaginatedResultDto
 */
export interface UserPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof UserPaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof UserPaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof UserPaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserPaginatedResultDto
     */
    'results': Array<User>;
}
/**
 * 
 * @export
 * @interface UserProfilePictureDto
 */
export interface UserProfilePictureDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfilePictureDto
     */
    'profilePictureUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePictureDto
     */
    'profilePictureUploadedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfilePictureDto
     */
    'removedProfilePictureAt'?: string;
}
/**
 * 
 * @export
 * @interface VerifyEmailDto
 */
export interface VerifyEmailDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailDto
     */
    'verificationId': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailDto
     */
    'verificationToken': string;
}
/**
 * 
 * @export
 * @interface VerifyPhoneDto
 */
export interface VerifyPhoneDto {
    /**
     * 
     * @type {string}
     * @memberof VerifyPhoneDto
     */
    'verificationId': string;
    /**
     * 
     * @type {string}
     * @memberof VerifyPhoneDto
     */
    'verificationToken': string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerActivateProgram: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountControllerActivateProgram', 'id', id)
            const localVarPath = `/v1/account/{id}/activate-program`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerCancelDeletion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountControllerCancelDeletion', 'id', id)
            const localVarPath = `/v1/account/{id}/cancel-deletion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/account/find-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindAll: async (limit: number, offset: number, order?: string, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('accountControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('accountControllerFindAll', 'offset', offset)
            const localVarPath = `/v1/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountControllerFindOne', 'id', id)
            const localVarPath = `/v1/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountControllerRemove', 'id', id)
            const localVarPath = `/v1/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} phone 
         * @param {string} phoneCountryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerSearchAccountPhone: async (phone: string, phoneCountryCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('accountControllerSearchAccountPhone', 'phone', phone)
            // verify required parameter 'phoneCountryCode' is not null or undefined
            assertParamExists('accountControllerSearchAccountPhone', 'phoneCountryCode', phoneCountryCode)
            const localVarPath = `/v1/account/check-by-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (phoneCountryCode !== undefined) {
                localVarQueryParameter['phoneCountryCode'] = phoneCountryCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerSwapRoles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountControllerSwapRoles', 'id', id)
            const localVarPath = `/v1/account/{id}/swap-roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerUpdateProgramStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountControllerUpdateProgramStatus', 'id', id)
            const localVarPath = `/v1/account/{id}/program-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerActivateProgram(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerActivateProgram(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerCancelDeletion(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerCancelDeletion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerFindAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountFilterUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerFindAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerFindAll(limit: number, offset: number, order?: string, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerFindAll(limit, offset, order, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} phone 
         * @param {string} phoneCountryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerSearchAccountPhone(phone: string, phoneCountryCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckAccountPhoneResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerSearchAccountPhone(phone, phoneCountryCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerSwapRoles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerSwapRoles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountControllerUpdateProgramStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountControllerUpdateProgramStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerActivateProgram(id: string, options?: any): AxiosPromise<AccountResponseDto> {
            return localVarFp.accountControllerActivateProgram(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerCancelDeletion(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountControllerCancelDeletion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindAccounts(options?: any): AxiosPromise<AccountFilterUserResponseDto> {
            return localVarFp.accountControllerFindAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindAll(limit: number, offset: number, order?: string, search?: string, options?: any): AxiosPromise<AccountPaginatedResultDto> {
            return localVarFp.accountControllerFindAll(limit, offset, order, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerFindOne(id: string, options?: any): AxiosPromise<AccountResponseDto> {
            return localVarFp.accountControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} phone 
         * @param {string} phoneCountryCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerSearchAccountPhone(phone: string, phoneCountryCode: string, options?: any): AxiosPromise<CheckAccountPhoneResponseDto> {
            return localVarFp.accountControllerSearchAccountPhone(phone, phoneCountryCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerSwapRoles(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountControllerSwapRoles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountControllerUpdateProgramStatus(id: string, options?: any): AxiosPromise<AccountResponseDto> {
            return localVarFp.accountControllerUpdateProgramStatus(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerActivateProgram(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerActivateProgram(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerCancelDeletion(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerCancelDeletion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerFindAccounts(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerFindAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerFindAll(limit: number, offset: number, order?: string, search?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerFindAll(limit, offset, order, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerRemove(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} phone 
     * @param {string} phoneCountryCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerSearchAccountPhone(phone: string, phoneCountryCode: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerSearchAccountPhone(phone, phoneCountryCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerSwapRoles(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerSwapRoles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountControllerUpdateProgramStatus(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).accountControllerUpdateProgramStatus(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AppVersionApi - axios parameter creator
 * @export
 */
export const AppVersionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appVersionControllerGetAppVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/app-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppVersionApi - functional programming interface
 * @export
 */
export const AppVersionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppVersionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appVersionControllerGetAppVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppVersionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appVersionControllerGetAppVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppVersionApi - factory interface
 * @export
 */
export const AppVersionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppVersionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appVersionControllerGetAppVersion(options?: any): AxiosPromise<AppVersionResponseDto> {
            return localVarFp.appVersionControllerGetAppVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppVersionApi - object-oriented interface
 * @export
 * @class AppVersionApi
 * @extends {BaseAPI}
 */
export class AppVersionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppVersionApi
     */
    public appVersionControllerGetAppVersion(options?: AxiosRequestConfig) {
        return AppVersionApiFp(this.configuration).appVersionControllerGetAppVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword: async (resetPasswordDto: ResetPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('authControllerChangePassword', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/v1/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmailChangePasswordDto} emailChangePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreatePasswordResetRequest: async (emailChangePasswordDto: EmailChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailChangePasswordDto' is not null or undefined
            assertParamExists('authControllerCreatePasswordResetRequest', 'emailChangePasswordDto', emailChangePasswordDto)
            const localVarPath = `/v1/auth/reset-password-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailChangePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('authControllerRefresh', 'authorization', authorization)
            const localVarPath = `/v1/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmailDto} verifyEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInVerifyEmail: async (verifyEmailDto: VerifyEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmailDto' is not null or undefined
            assertParamExists('authControllerSignInVerifyEmail', 'verifyEmailDto', verifyEmailDto)
            const localVarPath = `/v1/auth/sign-in/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyPhoneDto} verifyPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInVerifyPhone: async (verifyPhoneDto: VerifyPhoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyPhoneDto' is not null or undefined
            assertParamExists('authControllerSignInVerifyPhone', 'verifyPhoneDto', verifyPhoneDto)
            const localVarPath = `/v1/auth/sign-in/verify-phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyPhoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignInWithEmailDto} signInWithEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInWithEmail: async (signInWithEmailDto: SignInWithEmailDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInWithEmailDto' is not null or undefined
            assertParamExists('authControllerSignInWithEmail', 'signInWithEmailDto', signInWithEmailDto)
            const localVarPath = `/v1/auth/sign-in/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInWithEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignInWithPhoneDto} signInWithPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInWithPhone: async (signInWithPhoneDto: SignInWithPhoneDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInWithPhoneDto' is not null or undefined
            assertParamExists('authControllerSignInWithPhone', 'signInWithPhoneDto', signInWithPhoneDto)
            const localVarPath = `/v1/auth/sign-in/phone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInWithPhoneDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerify: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('authControllerVerify', 'authorization', authorization)
            const localVarPath = `/v1/auth/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerChangePassword(resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePassword(resetPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmailChangePasswordDto} emailChangePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCreatePasswordResetRequest(emailChangePasswordDto: EmailChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCreatePasswordResetRequest(emailChangePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefresh(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefresh(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyEmailDto} verifyEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInVerifyEmail(verifyEmailDto: VerifyEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInVerifyEmail(verifyEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyPhoneDto} verifyPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInVerifyPhone(verifyPhoneDto: VerifyPhoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInVerifyPhone(verifyPhoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignInWithEmailDto} signInWithEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInWithEmail(signInWithEmailDto: SignInWithEmailDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInWithEmailResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInWithEmail(signInWithEmailDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignInWithPhoneDto} signInWithPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignInWithPhone(signInWithPhoneDto: SignInWithPhoneDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInWithPhoneResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignInWithPhone(signInWithPhoneDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerify(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerVerify(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(resetPasswordDto: ResetPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerChangePassword(resetPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailChangePasswordDto} emailChangePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreatePasswordResetRequest(emailChangePasswordDto: EmailChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerCreatePasswordResetRequest(emailChangePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefresh(authorization: string, options?: any): AxiosPromise<AuthenticationResponseDto> {
            return localVarFp.authControllerRefresh(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmailDto} verifyEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInVerifyEmail(verifyEmailDto: VerifyEmailDto, options?: any): AxiosPromise<AuthenticationResponseDto> {
            return localVarFp.authControllerSignInVerifyEmail(verifyEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyPhoneDto} verifyPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInVerifyPhone(verifyPhoneDto: VerifyPhoneDto, options?: any): AxiosPromise<AuthenticationResponseDto> {
            return localVarFp.authControllerSignInVerifyPhone(verifyPhoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignInWithEmailDto} signInWithEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInWithEmail(signInWithEmailDto: SignInWithEmailDto, options?: any): AxiosPromise<SignInWithEmailResponseDto> {
            return localVarFp.authControllerSignInWithEmail(signInWithEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignInWithPhoneDto} signInWithPhoneDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignInWithPhone(signInWithPhoneDto: SignInWithPhoneDto, options?: any): AxiosPromise<SignInWithPhoneResponseDto> {
            return localVarFp.authControllerSignInWithPhone(signInWithPhoneDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerify(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerVerify(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {ResetPasswordDto} resetPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerChangePassword(resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerChangePassword(resetPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailChangePasswordDto} emailChangePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCreatePasswordResetRequest(emailChangePasswordDto: EmailChangePasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerCreatePasswordResetRequest(emailChangePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRefresh(authorization: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRefresh(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmailDto} verifyEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignInVerifyEmail(verifyEmailDto: VerifyEmailDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSignInVerifyEmail(verifyEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyPhoneDto} verifyPhoneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignInVerifyPhone(verifyPhoneDto: VerifyPhoneDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSignInVerifyPhone(verifyPhoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignInWithEmailDto} signInWithEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignInWithEmail(signInWithEmailDto: SignInWithEmailDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSignInWithEmail(signInWithEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignInWithPhoneDto} signInWithPhoneDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignInWithPhone(signInWithPhoneDto: SignInWithPhoneDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSignInWithPhone(signInWithPhoneDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerify(authorization: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerVerify(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommunityRulesAcceptanceApi - axios parameter creator
 * @export
 */
export const CommunityRulesAcceptanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AcceptCommunityRulesDto} acceptCommunityRulesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityRulesAcceptanceControllerAcceptCommunityRules: async (acceptCommunityRulesDto: AcceptCommunityRulesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptCommunityRulesDto' is not null or undefined
            assertParamExists('communityRulesAcceptanceControllerAcceptCommunityRules', 'acceptCommunityRulesDto', acceptCommunityRulesDto)
            const localVarPath = `/v1/community-rules-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptCommunityRulesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityRulesAcceptanceControllerFindOne: async (version: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('communityRulesAcceptanceControllerFindOne', 'version', version)
            const localVarPath = `/v1/community-rules-acceptance/{version}`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityRulesAcceptanceApi - functional programming interface
 * @export
 */
export const CommunityRulesAcceptanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityRulesAcceptanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AcceptCommunityRulesDto} acceptCommunityRulesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityRulesAcceptanceControllerAcceptCommunityRules(acceptCommunityRulesDto: AcceptCommunityRulesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityRulesAcceptance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityRulesAcceptanceControllerAcceptCommunityRules(acceptCommunityRulesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communityRulesAcceptanceControllerFindOne(version: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommunityRulesAcceptance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communityRulesAcceptanceControllerFindOne(version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityRulesAcceptanceApi - factory interface
 * @export
 */
export const CommunityRulesAcceptanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityRulesAcceptanceApiFp(configuration)
    return {
        /**
         * 
         * @param {AcceptCommunityRulesDto} acceptCommunityRulesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityRulesAcceptanceControllerAcceptCommunityRules(acceptCommunityRulesDto: AcceptCommunityRulesDto, options?: any): AxiosPromise<CommunityRulesAcceptance> {
            return localVarFp.communityRulesAcceptanceControllerAcceptCommunityRules(acceptCommunityRulesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communityRulesAcceptanceControllerFindOne(version: string, options?: any): AxiosPromise<CommunityRulesAcceptance> {
            return localVarFp.communityRulesAcceptanceControllerFindOne(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityRulesAcceptanceApi - object-oriented interface
 * @export
 * @class CommunityRulesAcceptanceApi
 * @extends {BaseAPI}
 */
export class CommunityRulesAcceptanceApi extends BaseAPI {
    /**
     * 
     * @param {AcceptCommunityRulesDto} acceptCommunityRulesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityRulesAcceptanceApi
     */
    public communityRulesAcceptanceControllerAcceptCommunityRules(acceptCommunityRulesDto: AcceptCommunityRulesDto, options?: AxiosRequestConfig) {
        return CommunityRulesAcceptanceApiFp(this.configuration).communityRulesAcceptanceControllerAcceptCommunityRules(acceptCommunityRulesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityRulesAcceptanceApi
     */
    public communityRulesAcceptanceControllerFindOne(version: string, options?: AxiosRequestConfig) {
        return CommunityRulesAcceptanceApiFp(this.configuration).communityRulesAcceptanceControllerFindOne(version, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FavoriteAccountApi - axios parameter creator
 * @export
 */
export const FavoriteAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFavoriteAccountDto} createFavoriteAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAccountControllerCreate: async (createFavoriteAccountDto: CreateFavoriteAccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFavoriteAccountDto' is not null or undefined
            assertParamExists('favoriteAccountControllerCreate', 'createFavoriteAccountDto', createFavoriteAccountDto)
            const localVarPath = `/v1/favorite-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFavoriteAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} favoriteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAccountControllerDelete: async (favoriteAccountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoriteAccountId' is not null or undefined
            assertParamExists('favoriteAccountControllerDelete', 'favoriteAccountId', favoriteAccountId)
            const localVarPath = `/v1/favorite-account/{favoriteAccountId}`
                .replace(`{${"favoriteAccountId"}}`, encodeURIComponent(String(favoriteAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAccountControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/favorite-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FavoriteAccountApi - functional programming interface
 * @export
 */
export const FavoriteAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FavoriteAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFavoriteAccountDto} createFavoriteAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteAccountControllerCreate(createFavoriteAccountDto: CreateFavoriteAccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteAccountResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteAccountControllerCreate(createFavoriteAccountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} favoriteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteAccountControllerDelete(favoriteAccountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteAccountControllerDelete(favoriteAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteAccountControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FavoriteAccountResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteAccountControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FavoriteAccountApi - factory interface
 * @export
 */
export const FavoriteAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FavoriteAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFavoriteAccountDto} createFavoriteAccountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAccountControllerCreate(createFavoriteAccountDto: CreateFavoriteAccountDto, options?: any): AxiosPromise<FavoriteAccountResponseDto> {
            return localVarFp.favoriteAccountControllerCreate(createFavoriteAccountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} favoriteAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAccountControllerDelete(favoriteAccountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.favoriteAccountControllerDelete(favoriteAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteAccountControllerFindAll(options?: any): AxiosPromise<Array<FavoriteAccountResponseDto>> {
            return localVarFp.favoriteAccountControllerFindAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FavoriteAccountApi - object-oriented interface
 * @export
 * @class FavoriteAccountApi
 * @extends {BaseAPI}
 */
export class FavoriteAccountApi extends BaseAPI {
    /**
     * 
     * @param {CreateFavoriteAccountDto} createFavoriteAccountDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteAccountApi
     */
    public favoriteAccountControllerCreate(createFavoriteAccountDto: CreateFavoriteAccountDto, options?: AxiosRequestConfig) {
        return FavoriteAccountApiFp(this.configuration).favoriteAccountControllerCreate(createFavoriteAccountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} favoriteAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteAccountApi
     */
    public favoriteAccountControllerDelete(favoriteAccountId: string, options?: AxiosRequestConfig) {
        return FavoriteAccountApiFp(this.configuration).favoriteAccountControllerDelete(favoriteAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoriteAccountApi
     */
    public favoriteAccountControllerFindAll(options?: AxiosRequestConfig) {
        return FavoriteAccountApiFp(this.configuration).favoriteAccountControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupApi - axios parameter creator
 * @export
 */
export const GroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateGroupDto} createGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerCreateInvitationGroup: async (createGroupDto: CreateGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGroupDto' is not null or undefined
            assertParamExists('groupControllerCreateInvitationGroup', 'createGroupDto', createGroupDto)
            const localVarPath = `/v1/group/test-group`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('groupControllerRemove', 'id', id)
            const localVarPath = `/v1/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupApi - functional programming interface
 * @export
 */
export const GroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateGroupDto} createGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerCreateInvitationGroup(createGroupDto: CreateGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvitationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerCreateInvitationGroup(createGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async groupControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.groupControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupApi - factory interface
 * @export
 */
export const GroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateGroupDto} createGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerCreateInvitationGroup(createGroupDto: CreateGroupDto, options?: any): AxiosPromise<Array<InvitationResponseDto>> {
            return localVarFp.groupControllerCreateInvitationGroup(createGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        groupControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.groupControllerRemove(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupApi - object-oriented interface
 * @export
 * @class GroupApi
 * @extends {BaseAPI}
 */
export class GroupApi extends BaseAPI {
    /**
     * 
     * @param {CreateGroupDto} createGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerCreateInvitationGroup(createGroupDto: CreateGroupDto, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).groupControllerCreateInvitationGroup(createGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupApi
     */
    public groupControllerRemove(id: string, options?: AxiosRequestConfig) {
        return GroupApiFp(this.configuration).groupControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeApi - axios parameter creator
 * @export
 */
export const MeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerAcceptInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('invitationControllerAcceptInvitation', 'invitationId', invitationId)
            const localVarPath = `/v1/me/invitation/{invitationId}/accept`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerApprovalRejectInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('invitationControllerApprovalRejectInvitation', 'invitationId', invitationId)
            const localVarPath = `/v1/me/invitation/{invitationId}/approval-reject`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerApproveInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('invitationControllerApproveInvitation', 'invitationId', invitationId)
            const localVarPath = `/v1/me/invitation/{invitationId}/approve`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateInvitationDto} createInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerCreateInvitation: async (createInvitationDto: CreateInvitationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvitationDto' is not null or undefined
            assertParamExists('invitationControllerCreateInvitation', 'createInvitationDto', createInvitationDto)
            const localVarPath = `/v1/me/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateInvitationGroupDto} createInvitationGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerCreateInvitationV2: async (createInvitationGroupDto: CreateInvitationGroupDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvitationGroupDto' is not null or undefined
            assertParamExists('invitationControllerCreateInvitationV2', 'createInvitationGroupDto', createInvitationGroupDto)
            const localVarPath = `/v2/me/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvitationGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerGetInvitations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerRejectInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('invitationControllerRejectInvitation', 'invitationId', invitationId)
            const localVarPath = `/v1/me/invitation/{invitationId}/reject`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateInvitationDto} updateInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerUpdateInvitation: async (updateInvitationDto: UpdateInvitationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateInvitationDto' is not null or undefined
            assertParamExists('invitationControllerUpdateInvitation', 'updateInvitationDto', updateInvitationDto)
            const localVarPath = `/v1/me/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvitationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AcceptTosDto} acceptTosDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerAcceptTos: async (acceptTosDto: AcceptTosDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptTosDto' is not null or undefined
            assertParamExists('meControllerAcceptTos', 'acceptTosDto', acceptTosDto)
            const localVarPath = `/v1/me/tos-acceptance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptTosDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerChangePassword: async (changePasswordDto: ChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            assertParamExists('meControllerChangePassword', 'changePasswordDto', changePasswordDto)
            const localVarPath = `/v1/me/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerFindCurrent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerFindRelatedAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/me/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMeDto} updateMeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerUpdate: async (updateMeDto: UpdateMeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMeDto' is not null or undefined
            assertParamExists('meControllerUpdate', 'updateMeDto', updateMeDto)
            const localVarPath = `/v1/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeApi - functional programming interface
 * @export
 */
export const MeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerAcceptInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerAcceptInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerApprovalRejectInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerApprovalRejectInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerApproveInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerApproveInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateInvitationDto} createInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerCreateInvitation(createInvitationDto: CreateInvitationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerCreateInvitation(createInvitationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateInvitationGroupDto} createInvitationGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerCreateInvitationV2(createInvitationGroupDto: CreateInvitationGroupDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerCreateInvitationV2(createInvitationGroupDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerGetInvitations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvitationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerGetInvitations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerRejectInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerRejectInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateInvitationDto} updateInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationControllerUpdateInvitation(updateInvitationDto: UpdateInvitationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationControllerUpdateInvitation(updateInvitationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AcceptTosDto} acceptTosDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meControllerAcceptTos(acceptTosDto: AcceptTosDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meControllerAcceptTos(acceptTosDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meControllerChangePassword(changePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meControllerFindCurrent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meControllerFindCurrent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meControllerFindRelatedAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meControllerFindRelatedAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateMeDto} updateMeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async meControllerUpdate(updateMeDto: UpdateMeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.meControllerUpdate(updateMeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeApi - factory interface
 * @export
 */
export const MeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerAcceptInvitation(invitationId: string, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerAcceptInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerApprovalRejectInvitation(invitationId: string, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerApprovalRejectInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerApproveInvitation(invitationId: string, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerApproveInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateInvitationDto} createInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerCreateInvitation(createInvitationDto: CreateInvitationDto, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerCreateInvitation(createInvitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateInvitationGroupDto} createInvitationGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerCreateInvitationV2(createInvitationGroupDto: CreateInvitationGroupDto, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerCreateInvitationV2(createInvitationGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerGetInvitations(options?: any): AxiosPromise<Array<InvitationResponseDto>> {
            return localVarFp.invitationControllerGetInvitations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerRejectInvitation(invitationId: string, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerRejectInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateInvitationDto} updateInvitationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationControllerUpdateInvitation(updateInvitationDto: UpdateInvitationDto, options?: any): AxiosPromise<InvitationResponseDto> {
            return localVarFp.invitationControllerUpdateInvitation(updateInvitationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AcceptTosDto} acceptTosDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerAcceptTos(acceptTosDto: AcceptTosDto, options?: any): AxiosPromise<User> {
            return localVarFp.meControllerAcceptTos(acceptTosDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.meControllerChangePassword(changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerFindCurrent(options?: any): AxiosPromise<User> {
            return localVarFp.meControllerFindCurrent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerFindRelatedAccounts(options?: any): AxiosPromise<Array<AccountDto>> {
            return localVarFp.meControllerFindRelatedAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMeDto} updateMeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        meControllerUpdate(updateMeDto: UpdateMeDto, options?: any): AxiosPromise<User> {
            return localVarFp.meControllerUpdate(updateMeDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeApi - object-oriented interface
 * @export
 * @class MeApi
 * @extends {BaseAPI}
 */
export class MeApi extends BaseAPI {
    /**
     * 
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerAcceptInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerAcceptInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerApprovalRejectInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerApprovalRejectInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerApproveInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerApproveInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateInvitationDto} createInvitationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerCreateInvitation(createInvitationDto: CreateInvitationDto, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerCreateInvitation(createInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateInvitationGroupDto} createInvitationGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerCreateInvitationV2(createInvitationGroupDto: CreateInvitationGroupDto, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerCreateInvitationV2(createInvitationGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerGetInvitations(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerGetInvitations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerRejectInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerRejectInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateInvitationDto} updateInvitationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public invitationControllerUpdateInvitation(updateInvitationDto: UpdateInvitationDto, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).invitationControllerUpdateInvitation(updateInvitationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AcceptTosDto} acceptTosDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meControllerAcceptTos(acceptTosDto: AcceptTosDto, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meControllerAcceptTos(acceptTosDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangePasswordDto} changePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meControllerChangePassword(changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meControllerFindCurrent(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meControllerFindCurrent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meControllerFindRelatedAccounts(options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meControllerFindRelatedAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMeDto} updateMeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeApi
     */
    public meControllerUpdate(updateMeDto: UpdateMeDto, options?: AxiosRequestConfig) {
        return MeApiFp(this.configuration).meControllerUpdate(updateMeDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MembershipApi - axios parameter creator
 * @export
 */
export const MembershipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membershipControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('membershipControllerRemove', 'id', id)
            const localVarPath = `/v1/membership/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembershipApi - functional programming interface
 * @export
 */
export const MembershipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembershipApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async membershipControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.membershipControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MembershipApi - factory interface
 * @export
 */
export const MembershipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembershipApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membershipControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.membershipControllerRemove(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembershipApi - object-oriented interface
 * @export
 * @class MembershipApi
 * @extends {BaseAPI}
 */
export class MembershipApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembershipApi
     */
    public membershipControllerRemove(id: string, options?: AxiosRequestConfig) {
        return MembershipApiFp(this.configuration).membershipControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RecentAccountApi - axios parameter creator
 * @export
 */
export const RecentAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentAccountControllerFindAccounts: async (limit: number, offset: number, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('recentAccountControllerFindAccounts', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('recentAccountControllerFindAccounts', 'offset', offset)
            const localVarPath = `/v1/recent-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecentAccountApi - functional programming interface
 * @export
 */
export const RecentAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecentAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recentAccountControllerFindAccounts(limit: number, offset: number, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recentAccountControllerFindAccounts(limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RecentAccountApi - factory interface
 * @export
 */
export const RecentAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecentAccountApiFp(configuration)
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recentAccountControllerFindAccounts(limit: number, offset: number, order?: string, options?: any): AxiosPromise<AccountPaginatedResultDto> {
            return localVarFp.recentAccountControllerFindAccounts(limit, offset, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecentAccountApi - object-oriented interface
 * @export
 * @class RecentAccountApi
 * @extends {BaseAPI}
 */
export class RecentAccountApi extends BaseAPI {
    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecentAccountApi
     */
    public recentAccountControllerFindAccounts(limit: number, offset: number, order?: string, options?: AxiosRequestConfig) {
        return RecentAccountApiFp(this.configuration).recentAccountControllerFindAccounts(limit, offset, order, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCompleteOnboarding: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/onboarding-completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate: async (createUserDto: CreateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserDto' is not null or undefined
            assertParamExists('userControllerCreate', 'createUserDto', createUserDto)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateResetPasswordToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerCreateResetPasswordToken', 'id', id)
            const localVarPath = `/v1/user/{id}/reset-password-request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {'admin' | 'customer' | 'physician' | 'coach'} [userType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAll: async (limit: number, offset: number, order?: string, userType?: 'admin' | 'customer' | 'physician' | 'coach', search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('userControllerFindAll', 'offset', offset)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerFindOne', 'id', id)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfilePicture: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetProfilePicture', 'userId', userId)
            const localVarPath = `/v1/user/profile-picture/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerRemove', 'id', id)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemoveProfilePicture: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerRemoveProfilePicture', 'userId', userId)
            const localVarPath = `/v1/user/profile-picture/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (id: string, updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerUpdate', 'id', id)
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdate', 'updateUserDto', updateUserDto)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateProfilePicture: async (file?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/profile-picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCompleteOnboarding(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCompleteOnboarding(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreate(createUserDto: CreateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreate(createUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateResetPasswordToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateResetPasswordToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {'admin' | 'customer' | 'physician' | 'coach'} [userType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindAll(limit: number, offset: number, order?: string, userType?: 'admin' | 'customer' | 'physician' | 'coach', search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindAll(limit, offset, order, userType, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetProfilePicture(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfilePictureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetProfilePicture(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRemoveProfilePicture(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfilePictureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerRemoveProfilePicture(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdate(id, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdateProfilePicture(file?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfilePictureDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateProfilePicture(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCompleteOnboarding(options?: any): AxiosPromise<User> {
            return localVarFp.userControllerCompleteOnboarding(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserDto} createUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreate(createUserDto: CreateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerCreate(createUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateResetPasswordToken(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerCreateResetPasswordToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {'admin' | 'customer' | 'physician' | 'coach'} [userType] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAll(limit: number, offset: number, order?: string, userType?: 'admin' | 'customer' | 'physician' | 'coach', search?: string, options?: any): AxiosPromise<UserPaginatedResultDto> {
            return localVarFp.userControllerFindAll(limit, offset, order, userType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindOne(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetProfilePicture(userId: string, options?: any): AxiosPromise<UserProfilePictureDto> {
            return localVarFp.userControllerGetProfilePicture(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRemoveProfilePicture(userId: string, options?: any): AxiosPromise<UserProfilePictureDto> {
            return localVarFp.userControllerRemoveProfilePicture(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerUpdate(id, updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdateProfilePicture(file?: any, options?: any): AxiosPromise<UserProfilePictureDto> {
            return localVarFp.userControllerUpdateProfilePicture(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCompleteOnboarding(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCompleteOnboarding(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserDto} createUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreate(createUserDto: CreateUserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreate(createUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateResetPasswordToken(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreateResetPasswordToken(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {'admin' | 'customer' | 'physician' | 'coach'} [userType] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerFindAll(limit: number, offset: number, order?: string, userType?: 'admin' | 'customer' | 'physician' | 'coach', search?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerFindAll(limit, offset, order, userType, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetProfilePicture(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetProfilePicture(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerRemove(id: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerRemoveProfilePicture(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerRemoveProfilePicture(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdate(id: string, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdate(id, updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdateProfilePicture(file?: any, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdateProfilePicture(file, options).then((request) => request(this.axios, this.basePath));
    }
}


